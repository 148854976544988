<script>
  import { chartOfAccounts, selectedOrgId } from '../../utils/store';
  import { db } from '../../utils/services';
  import { Shadow } from 'svelte-loading-spinners';
  import Pagination from '../../components/table/Pagination.svelte';
  import DataTable from '../../components/table/DataTable.svelte';
  import AccountModal from '../../components/modals/AccountModal.svelte';
  import DeleteModal from '../../components/modals/DeleteModal.svelte';
  import TableHeader from '../../components/table/TableHeader.svelte';
  import { redirect } from '@sveltech/routify';
  import { getNotificationsContext } from 'svelte-notifications';
  import Heading from '../../components/Heading.svelte';

  const { addNotification } = getNotificationsContext();

  let modal = false;
  let loading = false;
  let rowDelete = false;
  let currentEntry = {};

  let edit = false;
  let id = '';
  let accountNum = 0;
  let name = '';
  let description = '';

  let title = 'Chart of Accounts';
  let pageValues = [10, 25, 50, 100];
  let headers = ['Account #', 'Name', 'Description', 'Delete'];
  let keys = ['accountNum', 'name', 'description', 'delete'];

  let filteredData = [];
  let filterValue = '';

  let inputElement;
  let pageIndex = 1;
  let firstEntry = 1;
  let lastEntry = 5;
  let numPages = 1;
  let total = 0;
  let numPerPage = pageValues[0];

  if (
    !$selectedOrgId ||
    $selectedOrgId == 'undefined' ||
    $selectedOrgId == 'null'
  ) {
    $redirect('/settings');
  }

  function filterClose() {
    filterValue = '';
    changePage(1);
  }

  function changePage(page) {
    let newFirst = 0;
    let newLast = 0;

    let val = filterValue.toLowerCase();

    filteredData = $chartOfAccounts.filter(
      (value) =>
        value.name.toLowerCase().includes(val) ||
        value.accountNum.toString().toLowerCase().includes(val) ||
        value.description.toLowerCase().includes(val)
    );

    total = filteredData.length;
    numPages = Math.floor(total / numPerPage) + 1;

    // Check if page bounds go higher than number of pages
    if (page > numPages || page < 1) {
      return;
    }

    // Calculate new page numbers
    if (page === 1) {
      newFirst = 1;
      newLast = numPerPage;
    } else {
      newFirst = (page - 1) * numPerPage + 1;
      newLast = Number(newFirst) + Number(numPerPage) - 1;
    }

    // Check if new calculated total is over the total items
    if (newFirst <= total) {
      firstEntry = newFirst;
    }
    // Check if last page is over the total items
    if (newLast > total) {
      lastEntry = total;
    } else {
      lastEntry = newLast;
    }

    pageIndex = page;
    filteredData = filteredData.slice(firstEntry - 1, lastEntry);
  }

  function changeNumPerPage(num) {
    numPerPage = num;
    filterClose();
  }

  function catchPaginationEvent(event) {
    switch (event.type) {
      case 'page':
        changePage(event.value);
        break;
      case 'numPerPage':
        changeNumPerPage(event.value);
        break;
      default:
        console.log('Unsupported event type: ' + event.type);
    }
  }

  function catchHeaderEvent(event) {
    switch (event.type) {
      case 'filter':
        // event.value should be the search bar value
        filterValue = event.value;
        changePage(1);
        break;
      case 'close':
        filterClose();
        break;
      case 'modal':
        clearVars();
        modal = true;
        break;
      default:
        console.log('Unsupported event type: ' + event);
    }
  }

  function clearVars() {
    edit = false;
    id = '';
    accountNum = 0;
    name = '';
    description = '';
  }

  function rowClick(event) {
    edit = true;
    id = event.detail.id;
    accountNum = event.detail.accountNum;
    name = event.detail.name;
    description = event.detail.description;
    modal = true;
  }

  function deleteRow(event) {
    currentEntry = event.detail;
    rowDelete = true;
  }

  let disabled = false;
  $: if (
    currentEntry.accountNum == 1000 ||
    currentEntry.accountNum == 2000 ||
    currentEntry.accountNum == 3000
  ) {
    disabled = true;
  } else {
    disabled = false;
  }
  function deleteRowConfirmation(event) {
    if (!disabled) {
      db.collection('accounts')
        .doc($selectedOrgId)
        .collection('accounts')
        .doc(currentEntry.id)
        .delete()
        .then(() => {
          addNotification({
            text: 'Successfully deleted account!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
          });
          rowDelete = false;
        })
        .catch((error) => {
          console.log(error);
          addNotification({
            text: 'Failed to delete account.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
          });
        });
      rowDelete = false;
    }
  }

  $: if (!$chartOfAccounts) {
    loading = true;
  } else {
    loading = false;
    total = $chartOfAccounts.length;
    numPages = Math.floor(total / numPerPage) + 1;
    changePage(1);
  }
</script>

{#if modal}
  <AccountModal
    bind:show={modal}
    {edit}
    {id}
    {accountNum}
    oldAccountNum={accountNum}
    {name}
    {description}
  />
{/if}
{#if rowDelete}
  <DeleteModal
    bind:show={rowDelete}
    bind:entry={currentEntry}
    bind:disabled
    on:notify={deleteRowConfirmation}
  />
{/if}

{#if !loading}
  <Heading heading="Chart of Accounts" />
{/if}

{#if loading}
  <div class="w-full h-full flex justify-center items-center">
    <Shadow size="60" unit="px" duration="1s" color="#3b82f6" />
  </div>
{:else if !$chartOfAccounts}
  <div class="flex items-center justify-center">
    <div class="w-96 text-center grid text-sm text-gray-600">
      <span class="text-red-600 text-lg ">Error</span>
      <span
        >Failed to load accounts. Please reach out to support@sociusco.com for
        help.</span
      >
    </div>
  </div>
{:else}
  <div class="flex flex-col mt-4">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden light:border-b border-gray-200 dark:border-b-0 rounded-lg"
        >
          <TableHeader
            {inputElement}
            {filterValue}
            on:notify={(ev) => catchHeaderEvent(ev.detail)}
          />

          <DataTable
            on:notify={rowClick}
            on:delete={deleteRow}
            {filteredData}
            {headers}
            {keys}
          />

          <Pagination
            {pageIndex}
            {numPages}
            {firstEntry}
            {lastEntry}
            {total}
            {numPerPage}
            {pageValues}
            on:change={(ev) => catchPaginationEvent(ev.detail)}
          />
        </div>
      </div>
    </div>
  </div>
{/if}
