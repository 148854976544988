<script>
  import dayjs from 'dayjs';
  import { db, receiptsRef } from '../../utils/services';
  import { clickOutside } from '../../utils/clickOutside';
  import {
    user,
    vendors,
    customers,
    selectedOrgId,
    transactions,
    bankAccounts,
  } from '../../utils/store';
  import { createEventDispatcher, onMount } from 'svelte';
  import { getNotificationsContext } from 'svelte-notifications';
  import { Shadow } from 'svelte-loading-spinners';
  import Input from '../Input.svelte';
  import InputUsd from '../InputUSD.svelte';
  import InputPercentage from '../InputPercentage.svelte';
  import DatePicker from '../DatePicker.svelte';
  import Select from '../Select.svelte';
  import FileDropzone from '../FileDropzone.svelte';
  import { updateBankAccount } from '../../utils/utils';

  const { addNotification } = getNotificationsContext();

  const dispatch = createEventDispatcher();

  export let show = false;

  let loadingReceipts = false;
  let prevFiles = [];
  let files = {
    accepted: [],
    rejected: [],
  };

  let end = new Date();
  const getOptionLabel = (option) => option.name;
  const getSelectionLabel = (option) => option.name;
  const optionIdentifier = 'id';

  let transactionTypes = ['Income', 'Expense'];
  let methods = ['Cash', 'Check', 'ETF'];
  let incomeCategories = ['Other Income', 'Sales', 'Services'];
  let expenseCategories = [
    'Advertising/Marketing',
    'Insurance',
    'Meals',
    'Office Supplies',
    'Other Expense',
    'Professional Services',
    'Software',
    'Travel',
    'Utilities',
  ];
  let selectedIncomeCategory = {
    value: incomeCategories[0],
    label: incomeCategories[0],
  };
  let selectedExpenseCategory = {
    value: expenseCategories[0],
    label: expenseCategories[0],
  };
  let selectedVendor = {};
  let selectedCustomer = {};

  let salesTax = 0;
  $: salesTax = transactionType.value == 'Expense' ? 0 : salesTax;

  $: preTaxTotal = (0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  $: totalTax = Number(preTaxTotal) * (Number(salesTax) / 100);
  $: totalAmount = (
    Math.round((Number(preTaxTotal) + Number(totalTax)) * 100) / 100
  )
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if ($customers.length > 0) {
    selectedCustomer = $customers[0];
  }
  if ($vendors.length > 0) {
    selectedVendor = $vendors[0];
  }

  export let edit = false;
  export let editReceipts = edit;
  export let id = ''; // this is the transaction firestore object id
  export let transactionId = 0;
  export let transactionType = { value: 'Income', label: 'Income' };
  export let bankAccount;
  export let selectedSource = {};
  export let description = '';
  export let amountTotal = (0).toFixed(2);
  export let selectedDate = new Date(); // date user chose, defaults to today
  export let selectedMethod = { value: methods[0], label: methods[0] };
  export let selectedCategory = {
    value: incomeCategories[0],
    label: incomeCategories[0],
  };
  let oldAmount = amountTotal;

  async function urlToFile(url, name) {
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], name, { type: blob.type });
    return file;
  }

  function listAllImages() {
    loadingReceipts = true;
    // console.log('listing images');
    let numImages = 0;
    let imagesRef = receiptsRef.child(`${$selectedOrgId}/${transactionId}`);
    imagesRef
      .listAll()
      .then((res) => {
        if (res.items.length === 0) {
          loadingReceipts = false;
          editReceipts = false;
        }
        res.items.forEach((itemRef, index) => {
          //   console.log(`Retrieving image (${index}) ${itemRef.name}`);
          itemRef
            .getDownloadURL()
            .then((url) => {
              urlToFile(url, itemRef.name).then((file) => {
                files.accepted.push(file);
                prevFiles.push(file);
                numImages = numImages + 1;
                if (numImages === res.items.length) {
                  //   console.log('finished loading');
                  loadingReceipts = false;
                  editReceipts = false;
                }
              });
            })
            .catch(function (error) {
              console.log('error downloading file: ', error);
            });
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log('error listing images', error);
      });
  }

  onMount(() => {
    if (Object.keys(selectedSource).length != 0) {
      if (transactionType.value == 'Income') {
        selectedIncomeCategory = selectedCategory;
        selectedCustomer = {
          account: {
            accountNum: selectedSource.accountNum,
          },
          name: selectedSource.name,
          id: selectedSource.id,
          type: selectedSource.type,
        };
      } else if (transactionType.value == 'Expense') {
        selectedExpenseCategory = selectedCategory;
        selectedVendor = {
          account: {
            accountNum: selectedSource.accountNum,
          },
          name: selectedSource.name,
          id: selectedSource.id,
          type: selectedSource.type,
        };
      }
    }
    totalAmount = amountTotal.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  });

  function getTransactionNumber() {
    let numbers = [];
    numbers = $transactions.sort(function (a, b) {
      return a.transactionNumber < b.transactionNumber ? 1 : -1;
    });

    if (numbers.length == 0) {
      return 1;
    }

    return numbers[0].transactionNumber + 1;
  }

  async function uploadFiles(transactionId) {
    let deletedFiles = prevFiles.filter((x) => !files.accepted.includes(x));

    for (let i = 0; i < deletedFiles.length; i++) {
      let fileRef = receiptsRef.child(
        `${$selectedOrgId}/${transactionId}/${deletedFiles[i].name}`
      );

      // Delete the file
      fileRef
        .delete()
        .then(() => {
          //   console.log('successfully deleted file ', deletedFiles[i].name);
        })
        .catch((error) => {
          console.log('Failed to delete');
        });
    }

    for (let i = 0; i < files.accepted.length; i++) {
      let fileRef = receiptsRef.child(
        `${$selectedOrgId}/${transactionId}/${files.accepted[i].name}`
      );
      fileRef
        .put(files.accepted[i])
        .then((snapshot) => {
          //   console.log('Successfully uploaded file!');
        })
        .catch((err) => {
          console.log('error uploading: ', err);
        });
    }
  }

  function add() {
    let category = '';
    let type;
    if (transactionType.value == 'Income') {
      category = selectedIncomeCategory;
      type = 'customer';
      selectedSource = selectedCustomer;
    }
    if (transactionType.value == 'Expense') {
      category = selectedExpenseCategory;
      type = 'vendor';
      selectedSource = selectedVendor;
    }

    db.collection('transactions')
      .doc($selectedOrgId)
      .collection('transactions')
      .add({
        accountNum: selectedSource.account.accountNum,
        date: selectedDate,
        organizationId: $selectedOrgId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: $user.uid,
        lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        lastUpdatedBy: $user.uid,
        description: description,
        method: selectedMethod.value,
        category: category.value,
        totalAmount: totalAmount.replace(',', ''),
        type: transactionType.value,
        transactionNumber: getTransactionNumber(),
        invoiceId: null,
        bankAccount: bankAccount,
        source: {
          name: selectedSource.name,
          id: selectedSource.id,
          accountNum: selectedSource.account.accountNum,
          type: type,
        },
      })
      .then(() => {
        uploadFiles(transactionId);

        if (bankAccount != undefined && bankAccount != null) {
          updateBankAccount(
            bankAccount.id,
            transactionType.value,
            Number(totalAmount)
          )
            .then(() => {
              addNotification({
                text: 'Successfully updated transaction!',
                position: 'bottom-center',
                type: 'success',
                removeAfter: 2000,
              });
              show = false;
            })
            .catch((error) => {
              console.log(error);
              addNotification({
                text: 'Failed to update bank account balance from transaction.',
                position: 'bottom-center',
                type: 'error',
                removeAfter: 2000,
              });
              show = false;
            });
        } else {
          addNotification({
            text: 'Successfully updated transaction!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
          });
          show = false;
        }
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to add transaction.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  function update() {
    let category = '';
    let type;
    if (transactionType.value == 'Income') {
      category = selectedIncomeCategory;
      type = 'customer';
      selectedSource = selectedCustomer;
    }
    if (transactionType.value == 'Expense') {
      category = selectedExpenseCategory;
      type = 'vendor';
      selectedSource = selectedVendor;
    }

    db.collection('transactions')
      .doc($selectedOrgId)
      .collection('transactions')
      .doc(id)
      .update({
        accountNum: selectedSource.account.accountNum,
        date: selectedDate,
        lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        lastUpdatedBy: $user.uid,
        description: description,
        method: selectedMethod.value,
        category: category.value,
        bankAccount: bankAccount,
        totalAmount: totalAmount.replace(',', ''),
        type: transactionType.value,
        source: {
          name: selectedSource.name,
          id: selectedSource.id,
          accountNum: selectedSource.account.accountNum,
          type: type,
        },
      })
      .then(() => {
        uploadFiles(transactionId);

        const diff = Number(totalAmount) - Number(oldAmount);
        if (diff != 0 && bankAccount != undefined && bankAccount != null) {
          updateBankAccount(bankAccount.id, transactionType.value, diff)
            .then(() => {
              addNotification({
                text: 'Successfully updated transaction!',
                position: 'bottom-center',
                type: 'success',
                removeAfter: 2000,
              });
              show = false;
            })
            .catch((error) => {
              console.log(error);
              addNotification({
                text: 'Failed to update bank account balance from transaction.',
                position: 'bottom-center',
                type: 'error',
                removeAfter: 2000,
              });
              show = false;
            });
        } else {
          console.log(
            'skipping bank account update since transaction value didnt change or there wasnt a bank'
          );
          addNotification({
            text: 'Successfully updated transaction!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
          });
          show = false;
        }
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to update transaction.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  let no = false;
  function addCustomer() {
    dispatch('notify', { newCustomer: true });
  }
  function addVendor() {
    dispatch('notify', { newVendor: true });
  }
</script>

<div
  class="fixed z-50 inset-0 overflow-visible"
  on:click_outside={() => (show = false)}
>
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >
    <div
      class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full md:max-w-xl lg:max-w-2xl xl:max-w-4xl"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <!-- Heading -->
            <div class="w-full flex justify-between">
              <h3
                class="text-lg leading-6 font-medium text-gray-900 dark:text-white"
              >
                {#if !edit}
                  New Transaction
                {:else}
                  Edit Transaction
                {/if}
              </h3>
              <span
                on:click={() => (show = false)}
                class="hidden md:flex text-cfa-secondary-gray hover:bg-socius-primary hover:text-white rounded-full w-8 h-8 flex justify-center items-center"
              >
                ×
              </span>
            </div>

            <!-- Body of the modal -->
            <div class="mt-2 ">
              <!-- Row 1 -->
              <div class="w-full block md:flex">
                <div class="w-full md:w-1/2 mr-1 md:mr-4">
                  <Select
                    label="Transaction Type"
                    items={transactionTypes}
                    placeholder="Select transaction type"
                    bind:selectedValue={transactionType}
                  />
                </div>
                <div class="w-full md:w-1/2 md:ml-4 mt-4 md:mt-0">
                  <Select
                    label="Bank Account"
                    items={$bankAccounts}
                    {getOptionLabel}
                    {getSelectionLabel}
                    {optionIdentifier}
                    placeholder="Select bank account for transaction"
                    bind:selectedValue={bankAccount}
                  />
                </div>
              </div>

              <hr class="my-4 dark:hidden" />

              <div class="grid grid-col-12 gap-y-4 md:gap-y-0 mt-4">
                {#if $customers.length == 0 && !no && transactionType.value == 'Income'}
                  <div
                    class="row-span-1 flex items-center justify-between w-full text-xs border border-red-500 p-2 mb-2"
                  >
                    <span class="text-red-500"
                      >There are no customers. Would you like to add a new one?</span
                    >
                    <div class="text-sm">
                      <i
                        class="fas fa-plus-circle text-green-500 px-2"
                        on:click={addCustomer}
                      />
                      <i
                        class="fas fa-trash-alt text-red-500"
                        on:click={() => (no = true)}
                      />
                    </div>
                  </div>
                {/if}
                {#if $vendors.length == 0 && !no && transactionType.value == 'Expense'}
                  <div
                    class="row-span-1 flex items-center justify-between w-full text-xs border border-red-500 p-2 mb-2"
                  >
                    <span class="text-red-500"
                      >There are no vendors. Would you like to add a new one?</span
                    >
                    <div class="text-sm">
                      <i
                        class="fas fa-plus-circle text-green-500 px-2"
                        on:click={addVendor}
                      />
                      <i
                        class="fas fa-trash-alt text-red-500"
                        on:click={() => (no = true)}
                      />
                    </div>
                  </div>
                {/if}

                <!-- Row 1 -->
                <div class="row-span-1 md:flex md:justify-between w-full">
                  <div class="w-full md:w-1/2 mr-1 md:mr-4">
                    {#if transactionType.value == 'Income'}
                      <Select
                        label="Customer"
                        items={$customers}
                        {getOptionLabel}
                        {getSelectionLabel}
                        {optionIdentifier}
                        placeholder="Select customer"
                        bind:selectedValue={selectedCustomer}
                      />
                    {:else}
                      <Select
                        label="Vendor"
                        items={$vendors}
                        {getOptionLabel}
                        {getSelectionLabel}
                        {optionIdentifier}
                        placeholder="Select vendor"
                        bind:selectedValue={selectedVendor}
                      />
                    {/if}
                  </div>
                  <div class="w-full md:w-1/2 md:ml-4 mt-4 md:mt-0">
                    <Input
                      label="Description"
                      bind:value={description}
                      placeholder="Short description"
                    />
                  </div>
                </div>

                <!-- Row 2 -->
                <div
                  class="row-span-1 md:flex md:justify-between w-full mt-4 text-xs"
                >
                  <div class="w-full md:w-1/2 mr-1 md:mr-4">
                    <InputUsd
                      label="Pre-tax Total"
                      bind:value={preTaxTotal}
                      placeholder="0.00"
                    />
                  </div>
                  <div class="w-full md:w-1/2 md:ml-4 text-xs mt-4 md:mt-0">
                    <label
                      for="sales-tax"
                      class="text-small text-gray-600 dark:text-gray-400"
                    >
                      Sales Tax Rate
                      <span
                        class="ml-1 small-text relative bottom-1 hidden md:inline"
                        >*only if applicable</span
                      >
                    </label>
                    <InputPercentage
                      bind:value={salesTax}
                      placeholder="6"
                      disabled={transactionType.value == 'Expense'}
                    />
                  </div>
                </div>

                <!-- Row 3 -->
                <div
                  class="row-span-1 md:flex md:justify-between w-full mt-4 text-xs"
                >
                  <div class="w-full md:w-1/2 mr-1 md:mr-4">
                    <InputUsd
                      label="Total Amount"
                      bind:value={totalAmount}
                      placeholder="0.00"
                      disabled={!edit}
                    />
                  </div>
                  <div class="w-full md:w-1/2 ml-4 relative mt-4 md:mt-0">
                    <DatePicker bind:selectedDate {end} />
                  </div>
                </div>

                <!-- Row 4 -->
                <div class="row-span-1 md:flex md:justify-between w-full mt-4">
                  <div class="w-full md:w-1/2 mr-1 md:mr-4">
                    <Select
                      label="Method"
                      items={methods}
                      placeholder="Select method type"
                      listPlacement="top"
                      bind:selectedValue={selectedMethod}
                    />
                  </div>
                  <div class="w-full md:w-1/2 md:ml-4 mt-4 md:mt-0">
                    {#if transactionType.value == 'Income'}
                      <Select
                        label="Category"
                        items={incomeCategories}
                        placeholder="Select category"
                        listPlacement="top"
                        bind:selectedValue={selectedIncomeCategory}
                      />
                    {:else}
                      <Select
                        label="Category"
                        items={expenseCategories}
                        placeholder="Select category"
                        listPlacement="top"
                        bind:selectedValue={selectedExpenseCategory}
                      />
                    {/if}
                  </div>
                </div>

                {#if transactionType.value == 'Expense' && !editReceipts}
                  <div class="row-span-1 w-full mt-4">
                    <FileDropzone bind:files />
                  </div>
                {:else if transactionType.value == 'Expense' && editReceipts}
                  <div class="row-span-1 w-full mt-4">
                    <span
                      class="p-5 flex items-center justify-center text-xs border-2 border-dashed border-gray-800 dark:border-gray-300 cursor-pointer dark:bg-gray-800 dark:text-gray-300"
                      on:click={listAllImages}>View/Edit Files</span
                    >
                  </div>
                  {#if loadingReceipts}
                    <div
                      class="my-4 w-full h-full flex justify-center items-center"
                    >
                      <Shadow
                        size="25"
                        unit="px"
                        duration="1s"
                        color="#38d3ff"
                      />
                    </div>
                  {/if}
                {/if}
              </div>
            </div>

            <!-- Footer -->
            <div
              class="mt-6 w-full flex items-center justify-center cursor-pointer"
            >
              <div class="w-1/2 flex justify-end mr-4">
                {#if !edit}
                  <span
                    on:click={add}
                    class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
                  >
                    Add Transaction
                  </span>
                {:else}
                  <span
                    on:click={update}
                    class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
                  >
                    Update Transaction
                  </span>
                {/if}
              </div>
              <div class="w-1/2 flex justify-start ml-4">
                <span
                  on:click={() => (show = false)}
                  class="flex items-center justify-center text-xs text-red-600 hover:text-gray-900 h-8 w-16 rounded-full hover:bg-red-100"
                >
                  Close
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .small-text {
    font-size: 0.5rem;
  }
  .text-small {
    font-size: 0.65rem;
  }
</style>
