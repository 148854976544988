<script>
  import { db } from '../../utils/services';
  import { clickOutside } from '../../utils/clickOutside';
  import { selectedOrgId, user, bankAccountTypes } from '../../utils/store';
  import { getNotificationsContext } from 'svelte-notifications';
  import Input from '../Input.svelte';
  import Select from '../Select.svelte';
  import InputUsd from '../InputUSD.svelte';

  const { addNotification } = getNotificationsContext();

  const getOptionLabel = (option) => option.name;
  const getSelectionLabel = (option) => option.name;
  const optionIdentifier = 'type';

  export let show = false;
  export let edit = false;

  export let id = '';
  export let name = '';
  export let accountType = '';
  export let accountNumber = '0';
  export let balance = (0).toFixed(2);

  //   $: mask = accountNumber.replace(/(\d)(\d{4})/g, '*$2');
  $: mask = maskNumber();
  //   $: console.log(mask);

  $: accountNumberLast4 = accountNumber.replace(/(\w| )(?=(\w| ){4})/g, '');

  function maskNumber() {
    let accountMask = accountNumber;
    console.log(
      accountMask.slice(0, -4).replace(/./g, '*') + accountMask.slice(-4)
    );
  }

  let disabled;

  function add() {
    db.collection('bank_accounts')
      .doc($selectedOrgId)
      .collection('bank_accounts')
      .add({
        organizationId: $selectedOrgId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: $user.uid,
        lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        lastUpdatedBy: $user.uid,
        name: name,
        accountType: accountType,
        accountNumber: accountNumber,
        accountNumberLast4: accountNumberLast4,
        balance: balance,
      })
      .then(() => {
        addNotification({
          text: 'Successfully added account!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        show = false;
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to add account.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  function update() {
    db.collection('bank_accounts')
      .doc($selectedOrgId)
      .collection('bank_accounts')
      .doc(id)
      .update({
        lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        lastUpdatedBy: $user.uid,
        name: name,
        accountType: accountType,
        accountNumber: accountNumber,
        accountNumberLast4: accountNumberLast4,
        balance: balance,
      })
      .then(() => {
        addNotification({
          text: 'Successfully updated account!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        show = false;
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to update account.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }
</script>

<div
  class="fixed z-50 inset-0 overflow-y-auto"
  on:click_outside={() => (show = false)}
>
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >
    <div
      class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full md:max-w-xl lg:max-w-2xl xl:max-w-4xl"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <!-- Heading -->
            <div class="w-full flex justify-between">
              <h3
                class="text-lg leading-6 font-medium text-gray-900 dark:text-white"
              >
                {#if !edit}
                  New Bank Account
                {:else}
                  Edit Bank Account
                {/if}
              </h3>
              <span
                on:click={() => (show = false)}
                class="hidden md:flex text-cfa-secondary-gray hover:bg-socius-primary hover:text-white rounded-full w-8 h-8 flex justify-center items-center"
              >
                ×
              </span>
            </div>

            <!-- Body -->
            <div class="mt-2">
              <div class="grid grid-col-12 mt-4">
                <!-- Row 1 -->
                <div class="md:flex md:justify-between w-full">
                  <div class="w-full md:w-1/2 mr-1 md:mr-4">
                    <Input
                      label="Name"
                      bind:value={name}
                      placeholder="Name of account"
                      bind:disabled
                    />
                  </div>
                  <div class="w-full md:w-1/2 ml-1 md:ml-4">
                    <Select
                      label="Type"
                      items={$bankAccountTypes}
                      {getOptionLabel}
                      {getSelectionLabel}
                      {optionIdentifier}
                      placeholder="Select account type"
                      bind:selectedValue={accountType}
                    />
                  </div>
                </div>

                <!-- Row 2 -->
                <div class="md:flex md:justify-between w-full mt-4">
                  <div class="w-full md:w-1/2 mr-1 md:mr-4">
                    <Input
                      label="Account Number"
                      bind:value={accountNumber}
                      placeholder="Number of account"
                      bind:disabled
                    />
                  </div>
                  <div class="w-full md:w-1/2 ml-1 md:ml-4">
                    <InputUsd
                      label="Balance"
                      bind:value={balance}
                      placeholder="0.00"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Footer -->
            <div
              class="mt-6 w-full flex items-center justify-center cursor-pointer"
            >
              <div class="w-1/2 flex justify-end mr-4">
                {#if !edit}
                  <span
                    on:click={add}
                    class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
                  >
                    Add Account
                  </span>
                {:else}
                  <span
                    on:click={update}
                    class:cursor-not-allowed={disabled}
                    class:hover:bg-socius-primary={!disabled}
                    class:hover:text-white={!disabled}
                    class="flex items-center justify-center text-xs text-socius-alt h-8 w-28 rounded-full"
                  >
                    Update Account
                  </span>
                {/if}
              </div>
              <div class="w-1/2 flex justify-start ml-4">
                <span
                  on:click={() => (show = false)}
                  class="flex items-center justify-center text-xs text-red-600 hover:text-gray-900 h-8 w-16 rounded-full hover:bg-red-100"
                >
                  Close
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
