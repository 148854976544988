<script>
  import { db } from '../../utils/services';
  import { clickOutside } from '../../utils/clickOutside';
  import {
    user,
    chartOfAccounts,
    selectedOrgId,
    vendors,
    states,
  } from '../../utils/store';
  import { createEventDispatcher, onMount } from 'svelte';
  import { getNotificationsContext } from 'svelte-notifications';
  import Input from '../Input.svelte';
  import Select from '../Select.svelte';

  const { addNotification } = getNotificationsContext();

  const dispatch = createEventDispatcher();

  export let show = false;
  const getOptionLabel = (option) => option.accountNum + ' - ' + option.name;
  const getSelectionLabel = (option) => option.accountNum + ' - ' + option.name;
  const optionIdentifier = 'id';
  const getOptionLabelStates = (option) => `${option.full} - ${option.short}`;
  const getSelectionLabelStates = (option) => option.short;
  const optionIdentifierStates = 'short';

  let selectedAccount = '';
  if ($chartOfAccounts != undefined && $chartOfAccounts.length > 0) {
    selectedAccount = $chartOfAccounts[0];
  }

  let error = '';
  export let edit = false;
  export let id = '';
  export let name = '';
  export let oldName = '';
  export let email = '';
  export let description = '';
  export let address = '';
  export let zip = '';
  export let city = '';
  export let state = '';
  export let phoneNumber = '';
  export let account = {};
  $: phone = formatPhoneNumber(phone);
  onMount(() => {
    phone = phoneNumber;
    if (Object.keys(account).length != 0) {
      selectedAccount = account;
    }
  });

  function formatPhoneNumber() {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!phone) return phone;

    // clean the input for any non-digit values.
    const phoneNumber = phone.replace(/[^\d]/g, '');

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  function getId() {
    let numbers = [];
    numbers = $vendors
      .filter((item) => item.account.accountNum === selectedAccount.accountNum)
      .sort(function (a, b) {
        return a.vendorId < b.vendorId ? 1 : -1;
      });

    if (numbers.length == 0) {
      return selectedAccount.accountNum + 1;
    }

    return numbers[0].vendorId + 1;
  }

  $: if (name) error = '';
  function add() {
    let valid = true;

    // Find if the array contains an object by comparing the property value
    if (
      $vendors.some(
        (item) => item.name.toLowerCase().trim() === name.toLowerCase().trim()
      )
    ) {
      valid = false;
      error = 'Customer already exists.';
    }
    if (valid) {
      db.collection('vendors')
        .doc($selectedOrgId)
        .collection('vendors')
        .add({
          account: {
            id: selectedAccount.id,
            name: selectedAccount.name,
            accountNum: selectedAccount.accountNum,
          },
          organizationId: $selectedOrgId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: $user.uid,
          lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdatedBy: $user.uid,
          description: description,
          name: name.trim(),
          email: email,
          phone: phone,
          address: address,
          city: city,
          state: state.short,
          zip: zip,
          vendorId: getId(),
        })
        .then(() => {
          addNotification({
            text: 'Successfully added vendor!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
          });
          show = false;
        })
        .catch((error) => {
          console.log(error);
          addNotification({
            text: 'Failed to add vendor.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
          });
        });
    }
  }

  function update() {
    let valid = true;

    // Find if the array contains an object by comparing the property value
    if (
      $vendors.some(
        (item) => item.name.toLowerCase().trim() === name.toLowerCase().trim()
      )
    ) {
      if (oldName.toLowerCase().trim() !== name.toLowerCase().trim()) {
        valid = false;
        error = 'Customer already exists.';
      }
    }

    if (valid) {
      db.collection('vendors')
        .doc($selectedOrgId)
        .collection('vendors')
        .doc(id)
        .update({
          account: {
            id: selectedAccount.id,
            name: selectedAccount.name,
            accountNum: selectedAccount.accountNum,
          },
          lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdatedBy: $user.uid,
          description: description,
          name: name.trim(),
          email: email,
          phone: phone,
          address: address,
          city: city,
          state: state.short,
          zip: zip,
        })
        .then(() => {
          addNotification({
            text: 'Successfully updated vendor!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
          });
          show = false;
        })
        .catch((error) => {
          console.log(error);
          addNotification({
            text: 'Failed to update vendor.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
          });
        });
    }
  }

  let no = false;
  function addAccount() {
    dispatch('notify', { addAccount: true });
  }
</script>

<div
  class="fixed z-50 inset-0 overflow-y-auto"
  on:click_outside={() => (show = false)}
>
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >
    <div
      class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full md:max-w-xl lg:max-w-2xl xl:max-w-4xl"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <!-- Heading -->
            <div class="w-full flex justify-between">
              <h3
                class="text-lg leading-6 font-medium text-gray-900 dark:text-white"
              >
                {#if !edit}
                  New Vendor
                {:else}
                  Edit Vendor
                {/if}
              </h3>
              <span
                on:click={() => (show = false)}
                class="hidden md:flex text-cfa-secondary-gray hover:bg-socius-primary hover:text-white rounded-full w-8 h-8 flex justify-center items-center"
              >
                ×
              </span>
            </div>

            <!-- Body -->
            <div class="mt-2">
              <!-- Row 1 -->
              {#if ($chartOfAccounts == undefined || $chartOfAccounts.length) == 0 && !no}
                <div
                  class="row-span-1 flex items-center justify-between w-full text-xs border border-red-500 p-2 mb-2"
                >
                  <span class="text-red-500"
                    >There are no accounts. Would you like to add a new one?</span
                  >
                  <div class="text-sm">
                    <i
                      class="fas fa-plus-circle text-green-500 px-2"
                      on:click={addAccount}
                    />
                    <i
                      class="fas fa-trash-alt text-red-500"
                      on:click={() => (no = true)}
                    />
                  </div>
                </div>
              {:else}
                <div class="w-full flex">
                  <div class="w-1/2">
                    <Select
                      label="Account"
                      items={$chartOfAccounts}
                      {getOptionLabel}
                      {getSelectionLabel}
                      {optionIdentifier}
                      placeholder="Select transaction type"
                      bind:selectedValue={selectedAccount}
                    />
                  </div>
                </div>
              {/if}

              <hr class="my-4 dark:hidden" />

              <div class="grid grid-col-12 grid-row-5 mt-4">
                <!-- Row 2 -->
                <div class="row-span-1 flex justify-between w-full">
                  <div class="w-1/2 mr-1 md:mr-4">
                    <Input
                      label="Name"
                      bind:value={name}
                      placeholder="Name of vendor"
                    />
                    <p
                      class="text-xs p-2 text-red-500"
                      class:hidden={error === ''}
                    >
                      {error}
                    </p>
                  </div>
                  <div class="w-1/2 ml-1 md:ml-4">
                    <Input
                      label="Description"
                      bind:value={description}
                      placeholder="Short description"
                    />
                  </div>
                </div>

                <!-- Row 3 -->
                <div class="row-span-1 flex justify-between w-full mt-2">
                  <div class="w-1/2 mr-1 md:mr-4">
                    <Input
                      label="Email"
                      bind:value={email}
                      placeholder="Email of vendor"
                    />
                  </div>
                  <div class="w-1/2 ml-1 md:ml-4">
                    <Input
                      label="Phone"
                      bind:value={phone}
                      placeholder="Phone number of vendor"
                    />
                  </div>
                </div>

                <div class="row-span-1 flex justify-between w-full mt-4">
                  <div class="w-full">
                    <Input
                      label="Address"
                      bind:value={address}
                      placeholder="Address of vendor"
                    />
                  </div>
                </div>

                <div class="row-span-2 flex justify-between w-full mt-2 gap-4">
                  <div class="w-full lg:w-1/3">
                    <Input
                      label="City"
                      bind:value={city}
                      placeholder="Albuquerque"
                    />
                  </div>

                  <div class="w-1/2 lg:w-1/3">
                    <Select
                      label="State"
                      items={states}
                      getOptionLabel={getOptionLabelStates}
                      getSelectionLabel={getSelectionLabelStates}
                      optionIdentifier={optionIdentifierStates}
                      placeholder="NM"
                      listPlacement="top"
                      bind:selectedValue={state}
                    />
                  </div>

                  <div class="w-1/2 lg:w-1/3">
                    <Input
                      label="Zipcode"
                      bind:value={zip}
                      placeholder="87104"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Footer -->
            <div
              class="mt-6 w-full flex items-center justify-center cursor-pointer"
            >
              <div class="w-1/2 flex justify-end mr-4">
                {#if !edit}
                  <span
                    on:click={add}
                    class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
                  >
                    Add Vendor
                  </span>
                {:else}
                  <span
                    on:click={update}
                    class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
                  >
                    Update Vendor
                  </span>
                {/if}
              </div>
              <div class="w-1/2 flex justify-start ml-4">
                <span
                  on:click={() => (show = false)}
                  class="flex items-center justify-center text-xs text-red-600 hover:text-gray-900 h-8 w-16 rounded-full hover:bg-red-100"
                >
                  Close
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
