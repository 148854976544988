<script>
  import { user } from '../../utils/store';
  import { auth, firebaseAuth } from '../../utils/auth';
  import { getNotificationsContext } from 'svelte-notifications';
  import { redirect } from '@sveltech/routify';
  const { addNotification } = getNotificationsContext();

  function sendEmail() {
    emailSuccess = auth
      .sendVerificationEmail()
      .then((resp) => {
        addNotification({
          text: 'Successfully resent verification email!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        addNotification({
          text: 'Failed to resend verification email.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  function callAuthLogout() {
    console.log('calling auth function to logout');
    fetch(`/auth/logout`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((result) => {
        if (result.ok == true) {
          console.log('successfully logged out from auth function');
        } else {
          console.log(`error with [${result.status}]: ${result.statusText}`);
        }

        signout();
      })
      .catch((err) => {
        console.log('error logging out', err);
        signout();
      });
  }

  function signout() {
    firebaseAuth.signOut().then(
      function () {
        console.log('Signed Out');
        $user = undefined;
        authUI.reset();
        $redirect('/');
      },
      function (error) {
        console.error('Sign Out Error', error);
      }
    );
  }
</script>

<div
  class="flex items-center justify-center bg-gray-50 dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8 dark:text-gray-300"
>
  <div class="max-w-md w-full">
    <div class="mb-4 text-center">
      <!-- <img class="mx-auto h-12 w-auto" src="/logo128.png" alt="Workflow" /> -->
      <h2 class="mt-6 text-3xl font-extrabold text-gray-900 dark:text-white">
        Verify your email address
      </h2>
      <p class="mt-8 text-sm md:text-base text-gray-600 dark:text-gray-300">
        You're almost done! A verification message has been sent to
        <span class="font-bold text-gray-800 dark:text-white"
          >{$user.email}</span
        >
      </p>

      <p class="text-xs md:text-sm mt-8">
        Just check your email and follow the link to verify your account.
        <!-- Entered the wrong address?
        <span class="cursor-pointer text-socius-secondary"
          >Change your email.</span
        > -->
      </p>

      <hr class="mt-16" />
      <p class="mt-4 text-xs md:small-text ">
        Can't find the email?
        <span on:click={sendEmail} class="cursor-pointer text-socius-secondary"
          >Resend verification email</span
        >
        or contact
        <span class="">support@sociusco.com</span>
      </p>

      <div class="mt-24 text-xs md:small-text flex items-center justify-center">
        <button
          on:click={callAuthLogout}
          class="px-4 py-2 rounded-full text-red-600 text-xs hover:bg-red-600 hover:text-white flex items-center justify-center"
          ><i class="fa fa-sign-out-alt md:pl-2 md:pr-4" />Logout</button
        >
      </div>
    </div>
  </div>
</div>
