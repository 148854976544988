<script>
  export let label = '';
  export let value = '';
  export let disabled = false;
  export let placeholder = '';
</script>

{#if label != ''}
  <label
    for="value"
    class="text-small text-gray-700"
    class:dark:text-gray-400={!disabled}
    class:dark:text-gray-500={disabled}
  >
    {label}
  </label>
{/if}
<div
  class="relative dark:bg-gray-800 border-transparent border-b border-gray-600 focus:border-b-2 focus:border-socius-secondary transition duration-300 ease-in-out"
>
  <div
    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none dark:bg-gray-800"
    class:dark:text-gray-300={!disabled}
    class:dark:text-gray-500={disabled}
    class:text-black={!disabled}
    class:text-gray-300={disabled}
  >
    <span
      class="sm:text-sm"
      class:dark:text-gray-300={!disabled}
      class:dark:text-gray-500={disabled}
      class:text-gray-500={!disabled}
      class:text-gray-300={disabled}
    >
      %
    </span>
  </div>
  <input
    type="number"
    bind:value
    {disabled}
    min="0"
    class:dark:text-gray-300={!disabled}
    class:dark:text-gray-500={disabled}
    class:text-black={!disabled}
    class:text-gray-300={disabled}
    class:cursor-not-allowed={disabled}
    class="py-2 px-4 block w-full pl-7 pr-12 sm:text-sm focus:outline-none dark:bg-gray-800"
    {placeholder}
  />
</div>

<style>
  .text-small {
    font-size: 0.65rem;
  }
</style>
