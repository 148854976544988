<script>
  import dayjs from 'dayjs';
  import { Shadow } from 'svelte-loading-spinners';
  import Pagination from '../../components/table/Pagination.svelte';
  import DataTable from '../../components/table/DataTable.svelte';
  import TransactionModal from '../../components/modals/TransactionModal.svelte';
  import TableHeader from '../../components/table/TableHeader.svelte';
  import { transactions, selectedOrgId, bankBalance } from '../../utils/store';
  import { redirect } from '@sveltech/routify';
  import CustomerModal from '../../components/modals/CustomerModal.svelte';
  import AccountModal from '../../components/modals/AccountModal.svelte';
  import VendorModal from '../../components/modals/VendorModal.svelte';
  import DeleteModal from '../../components/modals/DeleteModal.svelte';
  import { db } from '../../utils/services';
  import { getNotificationsContext } from 'svelte-notifications';
  import Heading from '../../components/Heading.svelte';
  import { updateBankAccount } from '../../utils/utils';

  const { addNotification } = getNotificationsContext();

  let modal = false;
  let customerModal = false;
  let vendorModal = false;
  let accountModal = false;
  let loading = false;
  let rowDelete = false;
  let currentEntry = {};

  let pageValues = [10, 25, 50, 100];
  let headers = [
    'ID',
    'Type',
    'Category',
    'Method',
    'Account',
    'Source',
    'Amount ($)',
    'Date',
    'Delete',
  ];
  let keys = [
    'transactionNumber',
    'type',
    'category',
    'method',
    'source.accountNum',
    'source.name',
    'totalAmount',
    'date',
    'delete',
  ];

  let filteredData = [];
  let filterValue = '';

  let inputElement;
  let pageIndex = 1;
  let firstEntry = 1;
  let lastEntry = 5;
  let numPages = 1;
  let total = 0;
  let numPerPage = pageValues[0];

  let edit = false;
  let id = '';
  let transactionId = '';
  let transactionType = 'Income';
  let selectedSource = {};
  let description = '';
  let amountTotal = (0).toFixed(2);
  let dateChosen = false; // if user has chosen a date or not yet
  let selectedDate = new Date(); // date user chose, defaults to today
  let formattedDate = dayjs(selectedDate).format('MM/DD/YYYY'); // pretty date that user chose
  let selectedMethod = { value: '', label: '' };
  let selectedCategory = { value: '', label: '' };
  let bankAccount;

  if (
    !$selectedOrgId ||
    $selectedOrgId == 'undefined' ||
    $selectedOrgId == 'null'
  ) {
    $redirect('/settings');
  }

  function filterClose() {
    filterValue = '';
    changePage(1);
  }

  function changePage(page) {
    let newFirst = 0;
    let newLast = 0;

    let val = filterValue.toLowerCase();

    filteredData = $transactions
      .filter(
        (value) =>
          value.type.toLowerCase().includes(val) ||
          value.category.toLowerCase().includes(val) ||
          value.method.toLowerCase().includes(val) ||
          value.source.name.toLowerCase().includes(val) ||
          String(value.source.accountNum).includes(val) ||
          value.totalAmount.toString().toLowerCase().includes(val) ||
          dayjs(new Date(value.date.seconds * 1000))
            .format('MM/DD/YYYY')
            .toLowerCase()
            .includes(val)
      )
      .sort(function (a, b) {
        // return b.date - a.date;
        return a.transactionNumber < b.transactionNumber ? 1 : -1;
      });

    total = filteredData.length;
    numPages = Math.floor(total / numPerPage) + 1;

    // Check if page bounds go higher than number of pages
    if (page > numPages || page < 1) {
      return;
    }

    // Calculate new page numbers
    if (page === 1) {
      newFirst = 1;
      newLast = numPerPage;
    } else {
      newFirst = (page - 1) * numPerPage + 1;
      newLast = Number(newFirst) + Number(numPerPage) - 1;
    }

    // Check if new calculated total is over the total items
    if (newFirst <= total) {
      firstEntry = newFirst;
    }
    // Check if last page is over the total items
    if (newLast > total) {
      lastEntry = total;
    } else {
      lastEntry = newLast;
    }

    pageIndex = page;
    filteredData = filteredData.slice(firstEntry - 1, lastEntry);
  }

  function changeNumPerPage(num) {
    numPerPage = num;
    filterClose();
  }

  function catchPaginationEvent(event) {
    switch (event.type) {
      case 'page':
        changePage(event.value);
        break;
      case 'numPerPage':
        changeNumPerPage(event.value);
        break;
      default:
        console.log('Unsupported event type: ' + event.type);
    }
  }

  function catchHeaderEvent(event) {
    switch (event.type) {
      case 'filter':
        // event.value should be the search bar value
        filterValue = event.value;
        changePage(1);
        break;
      case 'close':
        filterClose();
        break;
      case 'modal':
        clearValues();
        modal = true;
        break;
      default:
        console.log('Unsupported event type: ' + event);
    }
  }

  function clearValues() {
    edit = false;
    id = '';
    transactionType = 'Income';
    selectedSource = {};
    description = '';
    amountTotal = (0).toFixed(2);
    dateChosen = false; // if user has chosen a date or not yet
    selectedDate = new Date(); // date user chose, defaults to today
    formattedDate = dayjs(selectedDate).format('MM/DD/YYYY'); // pretty date that user chose
    selectedMethod = { value: 'Cash', label: 'Cash' };
    selectedCategory = { value: 'Other Income', label: 'Other Income' };
  }

  function rowClick(event) {
    edit = true;
    id = event.detail.id;
    transactionId = event.detail.transactionId;
    transactionType = event.detail.type;
    selectedSource = event.detail.source;
    description = event.detail.description;
    amountTotal = event.detail.totalAmount;
    dateChosen = true;
    selectedDate = new Date(event.detail.date.seconds * 1000);
    formattedDate = dayjs(selectedDate).format('MM/DD/YYYY');
    selectedCategory = {
      value: event.detail.category,
      label: event.detail.category,
    };
    selectedMethod = {
      value: event.detail.method,
      label: event.detail.method,
    };
    bankAccount = event.detail.bankAccount;
    modal = true;
  }

  function deleteRow(event) {
    currentEntry = event.detail;
    rowDelete = true;
  }

  function deleteRowConfirmation(event) {
    db.collection('transactions')
      .doc($selectedOrgId)
      .collection('transactions')
      .doc(currentEntry.id)
      .delete()
      .then(() => {
        // Need to add/subtract the difference. Easiest way is to flip the type and let the update function take care of it
        let type = currentEntry.type == 'Income' ? 'Expense' : 'Income';

        if (
          currentEntry.bankAccount != undefined &&
          currentEntry.bankAccount != null
        ) {
          updateBankAccount(
            currentEntry.bankAccount.id,
            type,
            Number(currentEntry.totalAmount)
          )
            .then(() => {
              addNotification({
                text: 'Successfully deleted transaction!',
                position: 'bottom-center',
                type: 'success',
                removeAfter: 2000,
              });
              rowDelete = false;
            })
            .catch((error) => {
              console.log(error);
              addNotification({
                text: 'Failed to update bank account balance from transaction.',
                position: 'bottom-center',
                type: 'error',
                removeAfter: 2000,
              });
              rowDelete = false;
            });
        }
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to delete transaction.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
    rowDelete = false;
  }

  $: if (!$transactions) {
    loading = true;
  } else {
    loading = false;
    total = $transactions.length;
    numPages = Math.floor(total / numPerPage) + 1;
    changePage(1);
  }
</script>

{#if modal}
  <TransactionModal
    on:notify={(event) => {
      if (event.detail.newCustomer) {
        customerModal = true;
      }
      if (event.detail.newVendor) {
        vendorModal = true;
      }
      modal = false;
    }}
    bind:show={modal}
    {edit}
    {id}
    {transactionId}
    {transactionType}
    {selectedSource}
    {description}
    {amountTotal}
    {selectedDate}
    {selectedMethod}
    {selectedCategory}
    {bankAccount}
  />
{/if}
{#if customerModal}
  <CustomerModal
    on:notify={() => {
      accountModal = true;
      customerModal = false;
      modal = false;
    }}
    bind:show={customerModal}
  />
{/if}
{#if vendorModal}
  <VendorModal
    on:notify={() => {
      accountModal = true;
      vendorModal = false;
      modal = false;
    }}
    bind:show={vendorModal}
  />
{/if}
{#if accountModal}
  <AccountModal bind:show={accountModal} />
{/if}
{#if rowDelete}
  <DeleteModal
    bind:show={rowDelete}
    bind:entry={currentEntry}
    on:notify={deleteRowConfirmation}
  />
{/if}

{#if !loading}
  <Heading heading="Transactions" />
{/if}

{#if loading}
  <div class="w-full h-full flex justify-center items-center">
    <Shadow size="60" unit="px" duration="1s" color="#3b82f6" />
  </div>
{:else if !$transactions}
  <div class="flex items-center justify-center">
    <div class="w-96 text-center grid text-sm text-gray-600">
      <span class="text-red-600 text-lg ">Error</span>
      <span
        >Failed to load transactions. Please reach out to support@sociusco.com
        for help.</span
      >
    </div>
  </div>
{:else}
  <div class="flex flex-col mt-4">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden light:border-b border-gray-200 dark:border-b-0 rounded-lg"
        >
          <TableHeader
            {inputElement}
            {filterValue}
            on:notify={(ev) => catchHeaderEvent(ev.detail)}
          />

          <DataTable
            on:notify={rowClick}
            on:delete={deleteRow}
            {filteredData}
            {headers}
            {keys}
          />

          <Pagination
            {pageIndex}
            {numPages}
            {firstEntry}
            {lastEntry}
            {total}
            {numPerPage}
            {pageValues}
            on:change={(ev) => catchPaginationEvent(ev.detail)}
          />
        </div>
      </div>
    </div>
  </div>
{/if}
