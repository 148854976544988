<script>
  import { redirect } from '@sveltech/routify';
  import { bankAccounts, selectedOrgId } from '../../utils/store';
  import { Shadow } from 'svelte-loading-spinners';
  import Heading from '../../components/Heading.svelte';
  import BankAccount from '../../components/dashboard/BankAccount.svelte';
  import FloatingButton from '../../components/FloatingButton.svelte';
  import BankAccountModal from '../../components/modals/BankAccountModal.svelte';
  import DeleteModal from '../../components/modals/DeleteModal.svelte';
  import { db } from '../../utils/services';

  let loading = false;

  let open = false;
  let deleteEntry = false;
  $: selectedAccounts = [];

  $: if (!$bankAccounts) {
    loading = true;
  } else {
    loading = false;
  }

  if (
    !$selectedOrgId ||
    $selectedOrgId == 'undefined' ||
    $selectedOrgId == 'null'
  ) {
    $redirect('/settings');
  }

  function select(event) {
    if (event.selected) {
      selectedAccounts = [...selectedAccounts, event.account];
    } else {
      selectedAccounts = selectedAccounts.filter(
        (item) => item.id !== event.account.id
      );
    }
  }

  function add() {
    selectedAccounts = [];
    open = true;
  }

  function deleteConfirmation(event) {
    for (let i = 0; i < selectedAccounts.length; i++) {
      db.collection('bank_accounts')
        .doc($selectedOrgId)
        .collection('bank_accounts')
        .doc(selectedAccounts[i].id)
        .delete()
        .then(() => {
          console.log('success');
        })
        .catch((error) => {
          console.log(error);
        });
    }
    deleteEntry = false;
    selectedAccounts = [];
  }
</script>

{#if open}
  <BankAccountModal bind:show={open} />
{/if}

{#if deleteEntry}
  <DeleteModal bind:show={deleteEntry} on:notify={deleteConfirmation} />
{/if}

{#if !loading}
  <Heading heading="Bank Accounts">
    <div slot="button">
      {#if selectedAccounts.length != 0}
        <button
          on:click={() => (deleteEntry = true)}
          class="px-4 py-2 rounded text-red-600 bg-red-100 text-xs hover:bg-red-600 hover:text-white flex items-center justify-center"
          ><i class="fas fa-trash-alt mr-2" />Delete</button
        >
      {/if}

      <button
        on:click={() => (open = true)}
        class="px-4 py-2 rounded text-socius-secondary text-xs hover:bg-socius-secondary hover:text-white flex items-center justify-center inline md:hidden"
        ><i class="fas fa-plus-circle mr-2" />Add New</button
      >
    </div>
  </Heading>
{/if}

{#if loading}
  <div class="w-full h-full flex justify-center items-center">
    <Shadow size="60" unit="px" duration="1s" color="#3b82f6" />
  </div>
{:else if !$bankAccounts}
  <div class="flex items-center justify-center">
    <div class="w-96 text-center grid text-sm text-gray-600">
      <span class="text-red-600 text-lg ">Error</span>
      <span
        >Failed to load bank accounts. Please reach out to support@sociusco.com
        for help.</span
      >
    </div>
  </div>
{:else if $bankAccounts.length == 0}
  <div class="w-full flex justify-center items-center">
    <span class="dark:text-white"
      >There are no bank accounts added. Please add one to continue.</span
    >
  </div>
{:else}
  <div class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
    {#each $bankAccounts as acc}
      <BankAccount
        account={acc}
        reset={open}
        on:selected={(ev) => select(ev.detail)}
      />
    {/each}
  </div>
{/if}

<div class="hidden md:inline">
  <FloatingButton on:notify={add} />
</div>
