<script lang="ts">
  import {
    user,
    selectedOrgId,
    transactions,
    bankBalance,
    testAccounts,
  } from '../../utils/store';

  import TransactionModal from '../../components/modals/TransactionModal.svelte';
  import InvoiceModal from '../../components/modals/InvoiceModal.svelte';
  import VendorModal from '../../components/modals/VendorModal.svelte';
  import CustomerModal from '../../components/modals/CustomerModal.svelte';
  import { redirect } from '@sveltech/routify';
  import { onMount } from 'svelte';
  import { getNotificationsContext } from 'svelte-notifications';
  import SmallInfoCard from '../../components/dashboard/SmallInfoCard.svelte';
  import Header from '../../components/dashboard/Header.svelte';
  import PolarChart from '../../components/dashboard/PolarChart.svelte';
  import Button from '../../components/dashboard/Button.svelte';
  import InvoiceBar from '../../components/dashboard/InvoiceBar.svelte';
  import { db } from '../../utils/services';
  import { calculateRevenueAndExpenses } from '../../utils/utils';
  import ColumnChart from '../../components/dashboard/ColumnChart.svelte';

  const { addNotification } = getNotificationsContext();

  let loading = true;
  let transactionModal = false;
  let invoiceModal = false;
  let vendorModal = false;
  let customerModal = false;

  const month = new Date().toLocaleString('default', { month: 'long' });
  const today = new Date().getTime(); // get current epoch time
  $: numUnpaidInvoices = 0;
  $: numLateInvoices = 0;
  $: amtOpenInvoices = 0;
  $: amtLateInvoices = 0;
  $: revenueMonthly = 0;
  $: revenueYearly = 0;
  $: expensesMonthly = 0;
  $: expensesYearly = 0;
  $: revenueBreakdownMonthly = {
    series: [],
    labels: [],
  };
  $: revenueBreakdownYearly = {
    series: [],
    labels: [],
  };
  $: expensesBreakdownMonthly = {
    series: [],
    labels: [],
  };
  $: expensesBreakdownYearly = {
    series: [],
    labels: [],
  };
  $: cashFlow = [];

  if (
    !$selectedOrgId ||
    $selectedOrgId == 'undefined' ||
    $selectedOrgId == 'null'
  ) {
    $redirect('/settings');
  }

  $: $transactions && calculate();

  try {
    db.collection('invoices')
      .doc($selectedOrgId)
      .collection('invoices')
      .where('paid', '==', false)
      .onSnapshot((querySnapshot) => {
        numUnpaidInvoices = 0;
        numLateInvoices = 0;
        amtLateInvoices = 0;
        amtOpenInvoices = 0;
        querySnapshot.forEach((doc) => {
          let inv = doc.data();
          numUnpaidInvoices = numUnpaidInvoices + 1;
          if (inv.dueDate.seconds * 1000 < today) {
            numLateInvoices = numLateInvoices + 1;
            amtLateInvoices = amtLateInvoices + parseFloat(inv.totalAmount);
          } else {
            amtOpenInvoices = amtOpenInvoices + parseFloat(inv.totalAmount);
          }
        });
      });
  } catch (error) {
    console.log('Error in loading invoices for home: ', error);
  }

  function calculate() {
    let resp = calculateRevenueAndExpenses($transactions);

    revenueMonthly = resp.revenueMonthly;
    revenueYearly = resp.revenueYearly;
    expensesMonthly = resp.expensesMonthly;
    expensesYearly = resp.expensesYearly;
    revenueBreakdownYearly = resp.revenueBreakdownYearly;
    revenueBreakdownMonthly = resp.revenueBreakdownMonthly;
    expensesBreakdownYearly = resp.expensesBreakdownYearly;
    expensesBreakdownMonthly = resp.expensesBreakdownMonthly;
    cashFlow = resp.cashFlow;
  }

  onMount(() => {
    calculate();
  });
</script>

{#if transactionModal}
  <TransactionModal bind:show={transactionModal} />
{/if}
{#if invoiceModal}
  <InvoiceModal bind:show={invoiceModal} />
{/if}
{#if vendorModal}
  <VendorModal bind:show={vendorModal} />
{/if}
{#if customerModal}
  <CustomerModal bind:show={customerModal} />
{/if}

<Header />

<div class="grid grid-cols-12 lg:gap-6">
  <div
    class="col-span-12 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 mb-2"
  >
    <SmallInfoCard
      ytd={true}
      title="Revenue"
      amount={revenueYearly}
      color="green"
    />
    <SmallInfoCard
      ytd={true}
      title="Expenses"
      amount={expensesYearly}
      color="red"
    />
    <SmallInfoCard
      title="Bank Balance"
      amount={$bankBalance}
      color="blue"
      icon="fas fa-university"
    />
    <SmallInfoCard
      title="Total Open Invoices"
      amount={amtOpenInvoices + amtLateInvoices}
      color="green"
      icon="fas fa fa-file-invoice"
    />
  </div>

  <!-- First third of screen -->
  <div class="col-span-12 lg:col-span-8 mt-4 md:mt-0">
    <ColumnChart title="Cash Flow" series={cashFlow} />
  </div>

  <!-- Second third of screen -->
  <div class="col-span-12 lg:col-span-4 gap-y-2 w-full mt-4 lg:mt-0">
    <InvoiceBar
      lateInvoices={numLateInvoices}
      unpaidInvoices={numUnpaidInvoices}
      amtLate={amtLateInvoices}
      amtUnpaid={amtOpenInvoices}
    />

    <div class="mt-4">
      <div class="flex justify-between mb-2">
        <span
          class="text-gray-700 dark:text-white font-bold sm:small-text lg:text-xs "
          >Quick Links</span
        >
      </div>

      <div class="w-full grid grid-cols-12 gap-4 mt-4">
        <div class="col-span-6" on:click={() => (transactionModal = true)}>
          <Button icon="fa-coins" title="New Transaction" />
        </div>
        <div class="col-span-6" on:click={() => (invoiceModal = true)}>
          <Button icon="fa-file-invoice" title="New Invoice" />
        </div>
        <div class="col-span-6" on:click={() => (vendorModal = true)}>
          <Button icon="fa-warehouse" title="New Vendor" />
        </div>
        <div class="col-span-6" on:click={() => (customerModal = true)}>
          <Button icon="fa-user" title="New Customer" />
        </div>
        <div class="col-span-6" on:click={() => $redirect('/reports/view')}>
          <Button icon="fa fa-folder-open" title="View Reports" />
        </div>
      </div>
    </div>
  </div>

  <div
    class="col-span-12 w-full grid grid-cols-1 lg:grid-cols-3 gap-2 mb-2 mt-4 lg:mt-0"
  >
    <PolarChart
      title="Expenses Breakdown (Yearly)"
      series={expensesBreakdownYearly.series}
      labels={expensesBreakdownYearly.labels}
      total={expensesYearly}
    />
    <PolarChart
      title="Revenue Breakdown ({month})"
      series={revenueBreakdownMonthly.series}
      labels={revenueBreakdownMonthly.labels}
      total={revenueMonthly}
    />
    <PolarChart
      title="Revenue Breakdown (Yearly)"
      series={revenueBreakdownYearly.series}
      labels={revenueBreakdownYearly.labels}
      total={revenueYearly}
    />
  </div>
</div>
