<script>
  import Checkbox from '../Checkbox.svelte';
  import {
    selectedOrgId,
    user,
    transactions,
    organization,
    chartOfAccounts,
  } from '../../utils/store';
  import { db } from '../../utils/services';
  import { onMount } from 'svelte';
  import { getNotificationsContext } from 'svelte-notifications';

  const { addNotification } = getNotificationsContext();

  export let name = '';
  export let view = false;
  export let formattedStartDate = new Date();
  export let formattedEndDate = new Date();
  export let selectedAccounts = $chartOfAccounts;
  $: if (selectedAccounts == null || selectedAccounts == undefined)
    selectedAccounts = $chartOfAccounts;

  $: selectedAccounts && filterTransactions();
  $: formattedStartDate && filterTransactions();
  $: formattedEndDate && filterTransactions();

  let orgName = $organization ? $organization.name : 'Company Name';
  let orgAddress = $organization ? $organization.address : 'Company Address';
  let orgAddress2 = $organization
    ? $organization.city + ' ' + $organization.state + ', ' + $organization.zip
    : 'City State, ZIP';
  //   let orgEmail = $organization ? $organization.email : 'Company Email';
  let filteredTransactions = [];
  let expenseCategories = [
    'Advertising/Marketing',
    'Insurance',
    'Meals',
    'Office Supplies',
    'Other Expense',
    'Professional Services',
    'Software',
    'Travel',
    'Utilities',
  ];
  let incomeCategories = ['Other Income', 'Sales', 'Services'];
  let selectedExpenseCategories = expenseCategories;
  let selectedIncomeCategories = incomeCategories;

  let sales = 0;
  let services = 0;
  let otherIncome = 0;
  let totalIncome = 0;
  $: incomeMap = {
    Sales: sales.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    Services: services.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    'Other Income': otherIncome
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  };

  let utilities = 0;
  let meals = 0;
  let professionalServices = 0;
  let insurance = 0;
  let officeSupplies = 0;
  let software = 0;
  let travel = 0;
  let advertising = 0;
  let otherExpense = 0;
  let totalExpenses = 0;
  $: expenseMap = {
    'Advertising/Marketing': advertising
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    Insurance: insurance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    Meals: meals.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    'Office Supplies': officeSupplies
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    'Other Expense': otherExpense
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    'Professional Services': professionalServices
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    Software: software.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    Travel: travel.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    Utilities: utilities.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  };
  let pnl = 0;

  function arrayContains(searchString, arrayToFind) {
    if (arrayToFind === undefined || arrayToFind === null) return false;
    return arrayToFind.indexOf(searchString) > -1;
  }

  function itemInSelectedAccounts(item) {
    for (var i = 0; i < selectedAccounts.length; i++) {
      if (item.source.accountNum == selectedAccounts[i].accountNum) {
        return true;
      }
    }
    return false;
  }

  function handleExpenseCheck(event) {
    const checked = event.detail.checked;
    const label = event.detail.label;

    if (checked) {
      if (!arrayContains(label, selectedExpenseCategories)) {
        // Add and then reassign so the table gets updated
        selectedExpenseCategories.push(label);
        selectedExpenseCategories = selectedExpenseCategories.sort();
      }
    } else {
      //   Filter out the label
      selectedExpenseCategories = selectedExpenseCategories.filter(
        (item) => item !== label
      );
    }
    calculateTotals();
  }

  function handleIncomeCheck(event) {
    const checked = event.detail.checked;
    const label = event.detail.label;

    if (checked) {
      if (!arrayContains(label, selectedIncomeCategories)) {
        // Add and then reassign so the table gets updated
        selectedIncomeCategories.push(label);
        selectedIncomeCategories = selectedIncomeCategories.sort();
      }
    } else {
      //   Filter out the label
      selectedIncomeCategories = selectedIncomeCategories.filter(
        (item) => item !== label
      );
    }
    calculateTotals();
  }

  function saveStatement() {
    if (name == '') {
      addNotification({
        text: 'Must give statement a name.',
        position: 'bottom-center',
        removeAfter: 2000,
        type: 'error',
      });
    } else {
      db.collection('statements')
        .doc($selectedOrgId)
        .collection('statements')
        .add({
          organizationId: $selectedOrgId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: $user.uid,
          lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdatedBy: $user.uid,
          type: 'pnl',
          typePretty: 'Profit & Loss',
          name: name,
          expenses: {
            utilities: utilities,
            meals: meals,
            professionalServices: professionalServices,
            insurance: insurance,
            officeSupplies: officeSupplies,
            software: software,
            travel: travel,
            advertising: advertising,
            otherExpense: otherExpense,
            totalExpenses: totalExpenses,
          },
          income: {
            sales: sales,
            services: services,
            otherIncome: otherIncome,
            totalIncome: totalIncome,
          },
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          selectedExpenseCategories: selectedExpenseCategories,
          selectedIncomeCategories: selectedIncomeCategories,
          pnl: pnl,
        })
        .then(() => {
          addNotification({
            text: 'Successfully saved statement!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
          });
        })
        .catch((error) => {
          console.log(error);
          addNotification({
            text: 'Failed to add statement.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
          });
        });
    }
  }

  function filterTransactions() {
    filteredTransactions = $transactions.filter(function (item) {
      // It's in milliseconds so convert seconds to milli. End date needs to add a new day
      // to include transactions on the current day (it's < not <=).
      // There are 86400000 milliseconds in a day
      return (
        itemInSelectedAccounts(item) && // filter by selected accounts
        item.date.seconds * 1000 >= new Date(formattedStartDate).getTime() &&
        item.date.seconds * 1000 <
          new Date(formattedEndDate).getTime() + 86400000
      );
    });

    filteredTransactions = filteredTransactions.sort(function (a, b) {
      return b.date - a.date;
    });

    calculateTotals();
  }

  function calculateTotals() {
    // Reset income values
    sales = 0;
    services = 0;
    otherIncome = 0;
    totalIncome = 0;

    //   Reset expense values
    utilities = 0;
    meals = 0;
    professionalServices = 0;
    insurance = 0;
    officeSupplies = 0;
    software = 0;
    travel = 0;
    advertising = 0;
    otherExpense = 0;
    totalExpenses = 0;

    pnl = 0;

    // Recalculate values;
    for (let i = 0; i < filteredTransactions.length; i++) {
      switch (filteredTransactions[i].category) {
        //   Income
        case 'Sales':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedIncomeCategories
            )
          )
            sales += parseFloat(filteredTransactions[i].totalAmount);
          break;
        case 'Services':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedIncomeCategories
            )
          )
            services += parseFloat(filteredTransactions[i].totalAmount);
          break;
        case 'Other Income':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedIncomeCategories
            )
          )
            otherIncome += parseFloat(filteredTransactions[i].totalAmount);
          break;
        //   Expenses
        case 'Utilities':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedExpenseCategories
            )
          )
            utilities += parseFloat(filteredTransactions[i].totalAmount);
          break;
        case 'Meals':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedExpenseCategories
            )
          )
            meals += parseFloat(filteredTransactions[i].totalAmount);
          break;
        case 'Professional Services':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedExpenseCategories
            )
          )
            professionalServices += parseFloat(
              filteredTransactions[i].totalAmount
            );
          break;
        case 'Insurance':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedExpenseCategories
            )
          )
            insurance += parseFloat(filteredTransactions[i].totalAmount);
          break;
        case 'Office Supplies':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedExpenseCategories
            )
          )
            officeSupplies += parseFloat(filteredTransactions[i].totalAmount);
          break;
        case 'Software':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedExpenseCategories
            )
          )
            software += parseFloat(filteredTransactions[i].totalAmount);
          break;
        case 'Travel':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedExpenseCategories
            )
          )
            travel += parseFloat(filteredTransactions[i].totalAmount);
          break;
        case 'Advertising/Marketing':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedExpenseCategories
            )
          )
            advertising += parseFloat(filteredTransactions[i].totalAmount);
          break;
        case 'Other Expense':
          if (
            arrayContains(
              filteredTransactions[i].category,
              selectedExpenseCategories
            )
          )
            otherExpense += parseFloat(filteredTransactions[i].totalAmount);
          break;
        default:
          console.log(
            'Unsupported category type: ' + filteredTransactions[i].category
          );
      }
    }

    totalIncome = sales + services + otherIncome;
    totalExpenses =
      utilities +
      meals +
      professionalServices +
      insurance +
      officeSupplies +
      software +
      travel +
      advertising +
      otherExpense;
    pnl = totalIncome - totalExpenses;
  }

  onMount(() => {
    filterTransactions();
  });
</script>

<div class="dark:bg-gray-800 dark:text-gray-300 p-4 rounded">
  {#if !view}
    <div class="mb-4">
      <div class="mb-4">
        <div class="flex justify-center items-center mb-2">
          <p class="">Income Categories</p>
        </div>

        <div class="grid grid-cols-12 flex justify-center items-center">
          {#each incomeCategories as cat}
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <Checkbox label={cat} on:notify={handleIncomeCheck} />
            </div>
          {/each}
        </div>
      </div>

      <div class="">
        <div class="flex justify-center items-center mb-2">
          <p class="">Expense Categories</p>
        </div>

        <div class="grid grid-cols-12 flex justify-center items-center">
          {#each expenseCategories as cat}
            <div class="col-span-12 md:col-span-6 lg:col-span-4">
              <Checkbox label={cat} on:notify={handleExpenseCheck} />
            </div>
          {/each}
        </div>
      </div>

      <hr class="mt-4" />
    </div>
  {/if}

  <p class="w-full text-xl lg:text-3xl flex justify-center items-center">
    {orgName}
  </p>
  <p class="w-full text-sm lg:text-md flex justify-center items-center">
    {orgAddress}
  </p>
  <p class="w-full text-sm lg:text-md flex justify-center items-center mb-4">
    {orgAddress2}
  </p>
  <!-- <p class="w-full text-sm lg:text-md flex justify-center items-center mb-4">
  {orgEmail}
</p> -->

  <p class="w-full text-xl lg:text-3xl flex justify-center items-center">
    Profit & Loss Statement
  </p>
  <div class="text-sm">
    <p class="w-full print:mt-1 mt-2 flex justify-center items-center">
      For the period
      <span class="mx-2 underline">{formattedStartDate}</span>
      -
      <span class="ml-2 underline">{formattedEndDate}</span>
    </p>

    <div class="mt-4 overflow-x-auto">
      <div class="print:w-full inline-block overflow-hidden lg:w-full">
        <table
          class="print:my-0 mt-5 w-5/6 mx-auto text-gray-800 dark:text-gray-200 border-l-2 border-r-2 border-t-2 border-gray-300"
        >
          <tr class="text-left border-b-2 border-gray-300 font-bold uppercase">
            <th scope="col" class="px-6 py-3 tracking-wider"> Income </th>
            <th scope="col" class="px-6 py-3 tracking-wider"> $ </th>
            <th scope="col" class="px-6 py-3 tracking-wider"> $ </th>
          </tr>

          {#each selectedIncomeCategories as cat}
            <tr class="border-b border-gray-200">
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-300"
                  >
                    {cat}
                  </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-300"
                  >
                    {incomeMap[cat]}
                  </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap" />
            </tr>
          {/each}

          <tr class="border-b border-gray-200 print:hidden">
            <td class="px-4 py-3" />
            <td class="px-4 py-3" />
            <td class="px-4 py-3" />
          </tr>

          <tr class="text-left border-b-2 border-gray-300">
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            >
              Total Income
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            />
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            >
              {totalIncome.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </th>
          </tr>

          <tr class="border-b border-gray-200 h-12 print:hidden">
            <td class="px-4 py-3" />
            <td class="px-4 py-3" />
            <td class="px-4 py-3" />
          </tr>
        </table>

        <table
          class="print:my-0 w-5/6 mx-auto text-gray-800 dark:text-gray-200 border-l-2 border-r-2 border-gray-300"
        >
          <tr class="text-left border-b-2 border-gray-300">
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            >
              Expenses
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            >
              $
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            >
              $
            </th>
          </tr>

          {#each selectedExpenseCategories as cat}
            <tr class="border-b border-gray-200">
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-300"
                  >
                    {cat}
                  </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-300"
                  >
                    {expenseMap[cat]}
                  </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap" />
            </tr>
          {/each}

          <tr class="border-b border-gray-200 print:display-none">
            <td class="px-4 py-3" />
            <td class="px-4 py-3" />
            <td class="px-4 py-3" />
          </tr>

          <tr class="text-left border-b-2 border-gray-300">
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            >
              Total Expenses
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            />
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            >
              {totalExpenses.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </th>
          </tr>

          <tr class="border-b border-gray-200 h-12 print:h-4">
            <td class="px-4 py-3" />
            <td class="px-4 py-3" />
            <td class="px-4 py-3" />
          </tr>
        </table>

        <table
          class="print:my-0 mb-5 w-5/6 mx-auto text-gray-800 dark:text-gray-200 border-b-2 border-l-2 border-r-2 border-gray-300"
        >
          <tr class="text-left border-b-2 border-gray-300">
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            >
              Profit/Loss
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            />
            <th
              scope="col"
              class="px-6 py-3 text-left font-bold uppercase tracking-wider"
            >
              {#if pnl < 0}
                ({(pnl * -1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')})
              {:else}
                {pnl.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              {/if}
            </th>
          </tr>
        </table>
      </div>
    </div>
  </div>

  {#if !view}
    <div class="mt-8 pe-no-print flex justify-end">
      <button
        class="w-48 rounded uppercase px-8 py-4 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
        on:click={saveStatement}
      >
        Save
      </button>
    </div>
  {/if}
</div>
