<script>
  import { createEventDispatcher } from 'svelte';
  import FloatingButton from '../FloatingButton.svelte';

  export let addNew = true;
  export let title = '';
  export let filterValue = '';
  export let inputElement = '';

  const dispatch = createEventDispatcher();
</script>

<div
  class="flex w-full px-4 py-4 bg-white dark:bg-gray-800"
  class:justify-end={!addNew}
  class:justify-between={addNew}
>
  {#if title !== ''}
    <h4 class="text-grey-500 flex items-center justify-center">{title}</h4>
  {/if}
  {#if addNew}
    <button
      on:click={() => dispatch('notify', { type: 'modal' })}
      class="px-4 py-2 rounded text-socius-secondary text-xs hover:bg-socius-secondary hover:text-white flex items-center justify-center md:hidden"
      ><i class="fas fa-plus-circle mr-2" />Add New</button
    >
    <div class="hidden md:flex">
      <FloatingButton on:notify={() => dispatch('notify', { type: 'modal' })} />
    </div>
  {/if}
  <div class="flex relative w-48 md:w-64 xl:w-96">
    <div class="flex relative text-xs w-full">
      <div class="flex items-center w-full ">
        <input
          class="w-48 md:w-64 xl:w-96 py-2 border-b border-b-2 mr-3 px-2 leading-tight focus:outline-none focus:border-socius-secondary dark:bg-gray-800 dark:text-gray-300"
          type="text"
          placeholder="Search..."
          on:keydown={() =>
            dispatch('notify', {
              type: 'filter',
              value: filterValue,
            })}
          on:keyup={() =>
            dispatch('notify', {
              type: 'filter',
              value: filterValue,
            })}
          bind:value={filterValue}
          bind:this={inputElement}
          on:focus={inputElement.select()}
        />
        {#if filterValue}
          <i
            class="absolute right-0 top-0 mt-3 mr-4 fa fa-times text-gray-500 dark:text-gray-300"
            on:click={() => dispatch('notify', { type: 'close' })}
            aria-hidden="true"
          />
        {/if}
      </div>
    </div>
  </div>
</div>
