<script>
  import dayjs from 'dayjs';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let headers = [];
  export let keys = [];
  export let filteredData = [];
</script>

<table class="min-w-full divide-y divide-gray-200">
  <thead class="px-4 border-none">
    <tr class="bg-white dark:bg-gray-800">
      {#each headers as header}
        <th
          scope="col"
          class="px-6 py-2 text-left small-text font-bold text-socius-secondary uppercase tracking-wider border-b-2 border-socius-secondary"
        >
          {header}
        </th>
      {/each}
    </tr>
  </thead>
  <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 border-none">
    {#each filteredData as d}
      <tr
        class="border-none cursor-pointer hover:bg-gray-300 dark:text-gray-300 dark:hover:bg-gray-700"
      >
        {#each keys as key}
          <td class="px-6 py-2 whitespace-nowrap">
            <!-- I'm sure there's a better way to do this but it works and I need to ship it -->
            <div
              class="flex items-center"
              on:click={() => dispatch('notify', d)}
              class:justify-center={key.toLowerCase() == 'delete'}
            >
              <div>
                {#if typeof d[key] === 'boolean' && d[key] === true}
                  <!-- on:click={() => (d[key] = 'false')} -->
                  <div
                    class="text-xs font-medium text-socius-secondary"
                    on:click={() => dispatch('notify', d)}
                  >
                    <i class="fas fa-check" />
                  </div>
                {:else if typeof d[key] === 'boolean' && d[key] === false}
                  <!-- on:click={() => (d[key] = 'true')} -->
                  <div
                    class="text-xs font-medium text-red-500"
                    on:click={() => dispatch('notify', d)}
                  >
                    <i class="fas fa-times" />
                  </div>
                {:else if key.includes('date') || key.includes('dueDate') || key.includes('createdAt')}
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-300"
                    on:click={() => dispatch('notify', d)}
                  >
                    <!-- Dates can be created in milliseconds so take seconds and convert -->
                    {dayjs(new Date(d[key].seconds * 1000)).format(
                      'MM/DD/YYYY'
                    )}
                  </div>
                {:else if key.toLowerCase() == 'account'}
                  <div
                    class="text-xs font-medium dark:text-gray-300"
                    on:click={() => dispatch('notify', d)}
                  >
                    <!-- Dates can be created in milliseconds so take seconds and convert -->
                    {d[key].accountNum} - {d[key].name}
                  </div>
                {:else if key.toLowerCase() == 'totalamount'}
                  <div
                    class="text-xs font-medium dark:text-gray-300"
                    on:click={() => dispatch('notify', d)}
                  >
                    {d[key].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </div>
                {:else if key.toLowerCase() == 'delete'}
                  <div
                    class="text-xs font-medium text-red-500 flex items-center justify-center"
                    on:click={() => dispatch('delete', d)}
                  >
                    <i class="fas fa-trash" />
                  </div>
                {:else if key.toLowerCase() == 'print'}
                  <div
                    class="text-xs font-medium flex items-center justify-center"
                    on:click={() => dispatch('print', d)}
                  >
                    <i class="fas fa-print" />
                  </div>
                {:else}
                  <div
                    class="text-xs font-medium"
                    on:click={() => dispatch('notify', d)}
                  >
                    {#if key.split('.').length == 2}
                      {d[key.split('.')[0]][key.split('.')[1]]}
                    {:else}{d[key]}{/if}
                  </div>
                {/if}
              </div>
            </div>
          </td>
        {/each}
      </tr>
    {/each}
  </tbody>
</table>

<style>
  .small-text {
    font-size: 0.6rem;
  }
</style>
