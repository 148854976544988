<script>
  import { redirect } from '@sveltech/routify';
  import {
    statements,
    chartOfAccounts,
    selectedOrgId,
  } from '../../utils/store';
  import Select from '../../components/Select.svelte';
  import DatePicker from '../../components/DatePicker.svelte';

  import ProfitAndLoss from '../../components/statements/ProfitAndLoss.svelte';
  import TransactionLedger from '../../components/statements/TransactionLedger.svelte';
  import TotalOperatingExpense from '../../components/statements/TotalOperatingExpense.svelte';
  import Input from '../../components/Input.svelte';
  import Heading from '../../components/Heading.svelte';

  let name = '';
  let statementTypes = [
    'Profit & Loss',
    'Transaction Ledger',
    'Expense Report',
    'More to come...',
  ];
  let selectedAccounts = null;
  let selectedType = { value: '', label: 'Select statement type' };
  const today = new Date();
  let startDate = new Date(today.getFullYear(), today.getMonth(), 1);
  let formattedStartDate = '';
  let endDate = new Date();
  let formattedEndDate = '';

  const getOptionLabel = (option) => option.name;
  const getSelectionLabel = (option) => option.name;
  const optionIdentifier = 'id';

  function chooseAll() {
    selectedAccounts = $chartOfAccounts;
  }

  if (
    !$selectedOrgId ||
    $selectedOrgId == 'undefined' ||
    $selectedOrgId == 'null'
  ) {
    $redirect('/settings');
  }
</script>

<Heading heading="Reports" />

<div class="grid grid-cols-12 lg:gap-6 mt-4">
  <!-- First 2/3 of screen -->
  <div class="col-span-12 lg:col-span-8">
    <div
      class="shadow-lg rounded bg-white dark:bg-gray-800 dark:text-gray-300 p-6 mb-8 overflow-visible"
    >
      <div class="flex justify-between items-center">
        <p class="text-xl font-bold mb-4 dark:text-white">Generator</p>
      </div>

      <div class="w-full md:flex-row md:flex">
        <div class="w-full md:w-1/2 pr-4">
          <Select
            label="Type"
            items={statementTypes}
            placeholder="Type"
            bind:selectedValue={selectedType}
          />
        </div>

        {#if selectedType.value !== '' && selectedType.value !== 'More to come...'}
          <div class="w-full md:w-1/2 md:pl-4 mt-4 md:mt-0">
            <Input
              label="Name of Statement"
              bind:value={name}
              placeholder="Name of statement"
            />
          </div>
        {/if}
      </div>

      <div
        class="mt-6 md:mt-4 md:flex-row md:flex justify-between w-full h-36 md:h-16"
      >
        <div class="w-full md:w-1/2 md:mr-4 relative h-16">
          <DatePicker
            label="Start Date"
            bind:selectedDate={startDate}
            bind:end={endDate}
            bind:formattedDate={formattedStartDate}
          />
        </div>
        <div class="w-full md:w-1/2 md:ml-4 relative h-16 mt-4 md:mt-0">
          <DatePicker
            label="End Date"
            bind:selectedDate={endDate}
            bind:start={startDate}
            bind:formattedDate={formattedEndDate}
            end={today}
          />
        </div>
      </div>

      {#if $chartOfAccounts && $chartOfAccounts.length > 0}
        <div class="mt-6 md:mt-4 md:flex-row md:flex justify-between w-full ">
          <div class="w-full md:w-4/5 relative">
            <Select
              label="Accounts"
              items={$chartOfAccounts}
              placeholder="Filtering by all accounts"
              bind:selectedValue={selectedAccounts}
              {getOptionLabel}
              {getSelectionLabel}
              {optionIdentifier}
              isMulti="true"
              isClearable="true"
              listPlacement="top"
            />
          </div>
          <div
            class="w-full md:w-1/5 md:ml-4 relative mt-4 md:mt-0 flex items-center justify-center"
          >
            <button
              class="rounded uppercase px-4 py-2 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
              on:click={chooseAll}
            >
              All
            </button>
          </div>
        </div>
      {/if}
    </div>

    <!-- Statement -->
    {#if selectedType.value !== '' && selectedType.value !== 'More to come...'}
      <div class="bg-white dark:bg-gray-800 rounded shadow-lg p-4 w-full">
        {#if selectedType.value === 'Profit & Loss'}
          <ProfitAndLoss
            {name}
            {formattedStartDate}
            {formattedEndDate}
            {selectedAccounts}
          />
        {:else if selectedType.value === 'Transaction Ledger'}
          <TransactionLedger
            {name}
            {formattedStartDate}
            {formattedEndDate}
            {selectedAccounts}
          />
        {:else if selectedType.value === 'Expense Report'}
          <TotalOperatingExpense
            {name}
            {formattedStartDate}
            {formattedEndDate}
            {selectedAccounts}
          />
        {/if}
      </div>
    {/if}
  </div>

  <!-- Second Third of Screen -->
  <div class="col-span-12 lg:col-span-4 w-full mt-4 lg:mt-0">
    <div
      class="bg-white dark:bg-gray-800 dark:text-gray-300 rounded shadow-lg p-4 w-full"
    >
      <div class="text-sm flex justify-between border-b border-gray-300 pb-2">
        <span class="text-gray-700 dark:text-white">Recent Reports</span>
      </div>

      <div class="mt-4">
        {#if $statements && $statements.length == 0}
          <span class="text-xs">View recently generated statements here</span>
        {:else if $statements}
          {#each Array(5) as _, i}
            {#if $statements[i]}
              <a
                href="/reports/view?id={$statements[i].id}"
                class="block text-xs hover:text-socius-secondary my-1"
                >{i + 1}. {$statements[i].name}</a
              >
            {/if}
          {/each}
        {/if}
      </div>

      <div class="mt-4">
        {#if $statements && $statements.length > 5}
          <div
            class="border-t border-gray-300 text-socius-secondary flex justify-center items-center pt-2"
          >
            <a href="/reports/view" class="text-xs cursor-pointer">View All</a>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
