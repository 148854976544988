<script>
  import Login from './Login.svelte';
  import EmailVerification from './EmailVerification.svelte';
  import { user } from '../../utils/store';
  import { authUI, authUIConfig } from '../../utils/auth';

  //   console.log(authUI.isPendingRedirect());
  if (!authUI.isPendingRedirect()) {
    authUI.reset();
  }
  authUI.start('#firebaseui-auth-container', authUIConfig);
</script>

<Login />
