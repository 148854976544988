<script>
  import dayjs from 'dayjs';
  import {
    selectedOrgId,
    user,
    transactions,
    organization,
    chartOfAccounts,
  } from '../../utils/store';
  import { db } from '../../utils/services';
  import { onMount } from 'svelte';
  import { getNotificationsContext } from 'svelte-notifications';

  const { addNotification } = getNotificationsContext();

  export let view = false;
  export let name = '';
  export let formattedStartDate = dayjs(new Date()).format('MM/DD/YYYY');
  export let formattedEndDate = dayjs(new Date()).format('MM/DD/YYYY');
  export let selectedAccounts = $chartOfAccounts;
  $: if (selectedAccounts == null || selectedAccounts == undefined)
    selectedAccounts = $chartOfAccounts;

  $: selectedAccounts && filterTransactions();
  $: formattedStartDate && filterTransactions();
  $: formattedEndDate && filterTransactions();

  let orgName = $organization ? $organization.name : 'Company Name';
  let orgAddress = $organization ? $organization.address : 'Company Address';
  let orgAddress2 = $organization
    ? $organization.city + ' ' + $organization.state + ', ' + $organization.zip
    : 'City State, ZIP';
  //   let orgEmail = $organization ? $organization.email : 'Company Email';
  let totalAmount = 0;
  let totalString = '';
  let filteredTransactions = [];

  function itemInSelectedAccounts(item) {
    for (var i = 0; i < selectedAccounts.length; i++) {
      if (item.source.accountNum == selectedAccounts[i].accountNum) {
        return true;
      }
    }
    return false;
  }

  function saveStatement() {
    if (name == '') {
      addNotification({
        text: 'Must give statement a name.',
        position: 'bottom-center',
        removeAfter: 2000,
        type: 'error',
      });
    } else {
      db.collection('statements')
        .doc($selectedOrgId)
        .collection('statements')
        .add({
          organizationId: $selectedOrgId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: $user.uid,
          lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdatedBy: $user.uid,
          type: 'transactionLedger',
          typePretty: 'Transaction Ledger',
          name: name,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          transactions: filteredTransactions,
          totalAmount: totalAmount,
        })
        .then(() => {
          addNotification({
            text: 'Successfully saved statement!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
          });
        })
        .catch((error) => {
          console.log(error);
          addNotification({
            text: 'Failed to add statement.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
          });
        });
    }
  }

  let headers = ['Date', 'Type', 'Category', 'Method', 'Source', 'Amount'];

  function filterTransactions() {
    filteredTransactions = $transactions.filter(function (item) {
      // It's in milliseconds so convert seconds to milli. End date needs to add a new day
      // to include transactions on the current day (it's < not <=).
      // There are 86400000 milliseconds in a day
      return (
        itemInSelectedAccounts(item) && // filter by selected accounts
        item.date.seconds * 1000 >= new Date(formattedStartDate).getTime() &&
        item.date.seconds * 1000 <
          new Date(formattedEndDate).getTime() + 86400000
      );
    });

    filteredTransactions = filteredTransactions.sort(function (a, b) {
      return b.date - a.date;
    });

    calculateTotals();
  }

  function calculateTotals() {
    totalAmount = 0;
    if (filteredTransactions.length > 0) {
      for (let i = 0; i < filteredTransactions.length; i++) {
        let transaction = filteredTransactions[i];
        let amt = 0;
        if (transaction.type == 'Expense') {
          amt = parseFloat(transaction.totalAmount) * -1;
        } else {
          amt = parseFloat(transaction.totalAmount);
        }
        totalAmount += amt;
      }

      if (totalAmount < 0) {
        totalString = (totalAmount * -1)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        totalString = totalAmount
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
  }

  onMount(() => {
    filterTransactions();
  });
</script>

<div class="dark:bg-gray-800 dark:text-gray-300 p-4 rounded">
  <p class="w-full text-xl lg:text-3xl flex justify-center items-center">
    {orgName}
  </p>
  <p class="w-full text-sm lg:text-md flex justify-center items-center">
    {orgAddress}
  </p>
  <p class="w-full text-sm lg:text-md flex justify-center items-center mb-4">
    {orgAddress2}
  </p>
  <!-- <p class="w-full text-sm lg:text-md flex justify-center items-center mb-4">
  {orgEmail}
</p> -->

  <p class="w-full text-xl lg:text-3xl flex justify-center items-center">
    Transaction Ledger
  </p>
  <div class="text-sm">
    <p class="w-full mt-2 flex justify-center items-center">
      For the period
      <span class="ml-2 underline mr-2">{formattedStartDate}</span>
      -
      <span class="ml-2 underline">{formattedEndDate}</span>
    </p>
  </div>

  <div class="overflow-x-auto">
    <div class="inline-block overflow-hidden xl:w-full">
      <table
        class="rounded-t-lg m-5 w-5/6 mx-auto text-gray-800 dark:text-gray-300 border-2 border-gray-300 text-xs"
      >
        <thead class="px-4">
          <tr class="text-left border-b-2 border-gray-300 font-bold uppercase">
            {#each headers as header}
              <th scope="col" class="px-6 py-3 tracking-wider">
                {header}
              </th>
            {/each}
          </tr>
        </thead>
        <tbody class="">
          {#each filteredTransactions as transaction}
            <tr class="border-b border-gray-200">
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-200"
                  >
                    {dayjs(new Date(transaction.date.seconds * 1000)).format(
                      'MM/DD/YYYY'
                    )}
                  </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-200"
                  >
                    {transaction.type}
                  </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-200"
                  >
                    {transaction.category}
                  </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-200"
                  >
                    {transaction.method}
                  </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-200"
                  >
                    {transaction.source.name}
                  </div>
                </div>
              </td>
              <td class="px-6 py-2 whitespace-nowrap">
                <div class="flex items-center">
                  <div
                    class="text-xs font-medium text-gray-900 dark:text-gray-200"
                  >
                    {#if transaction.type == 'Income'}
                      <span
                        >$ {transaction.totalAmount.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ','
                        )}</span
                      >
                    {:else if transaction.type == 'Expense'}
                      <span
                        >$ ({transaction.totalAmount.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ','
                        )})</span
                      >
                    {/if}
                  </div>
                </div>
              </td>
            </tr>
          {/each}

          <tr class="border-b border-gray-200 h-12">
            <td class="px-4 py-3" />
            <td class="px-4 py-3" />
            <td class="px-4 py-3" />
          </tr>

          <tr class="text-left border-b-2 border-gray-300">
            <th class="px-4 py-3">Total Transactions</th>
            <th class="px-4 py-3">{filteredTransactions.length}</th>
            <th class="px-4 py-3" />
            <th class="px-4 py-3" />
            <th class="px-4 py-3">Total Amount </th>
            <th class="px-4 py-3">
              {#if totalAmount < 0}
                <span>$ ({totalString})</span>
              {:else}
                <span>$ {totalString}</span>
              {/if}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  {#if !view}
    <div class="mt-8 pe-no-print flex justify-end">
      <button
        class="w-48 rounded uppercase px-8 py-4 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
        on:click={saveStatement}
      >
        Save
      </button>
    </div>
  {/if}
</div>
