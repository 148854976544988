<script lang="ts">
  import dayjs from 'dayjs';
  import { redirect } from '@sveltech/routify';
  import { printInvoice, organization } from '../../utils/store';
  import Bowser from 'bowser';
  import { PrintElements } from '../../utils/print_elements';
  import { getNotificationsContext } from 'svelte-notifications';

  const { addNotification } = getNotificationsContext();

  let loading = true;
  let subtotal = 0;
  let totalTax = 0;
  let totalAmount = 0;

  try {
    $: name = $organization.name ? $organization.name : '';
  } catch (err) {
    $redirect('/invoices');
  }

  $: if ($printInvoice == undefined) {
    $redirect('/invoices');
  } else {
    loading = false;
    subtotal = Number($printInvoice.totalAmount);
    totalTax = Number(subtotal * ($printInvoice.taxRate / 100));
    totalAmount = subtotal + totalTax;
  }

  const headers = ['Description', 'QTY', 'Unit Price'];
  //   const browser = Bowser.getParser(window.navigator.userAgent);
  const browser = Bowser.parse(window.navigator.userAgent);

  function printStatement() {
    // console.log('print!');
    // console.log('Browser: ', browser);
    if (
      browser.browser.name.includes('Safari') &&
      browser.platform.type === 'desktop'
    ) {
      document.execCommand('print');
    } else if (
      browser.browser.name.includes('Firefox') &&
      browser.platform.type !== 'desktop'
    ) {
      // not supported
      addNotification({
        text: "Firefox mobile isn't supported.",
        position: 'bottom-center',
        type: 'error',
        removeAfter: 2000,
      });
    } else {
      PrintElements.print(document.getElementById('TARGET'));
    }
  }
</script>

{#if !loading}
  <div id="TARGET" class="p-4 sm:p-16 bg-white rounded">
    <div
      class="block print:flex sm:flex justify-start print:justify-between sm:justify-between"
    >
      <div
        class="text-xl uppercase font-bold text-gray-800 inline print:hidden sm:hidden"
      >
        Invoice
      </div>
      <div>
        <p class="text-lg text-gray-800">{name}</p>
        {#if $organization.address}<p class="text-gray-600">
            {$organization.address}
          </p>
          <p class="text-gray-600">
            {$organization.city}
            {$organization.state}, {$organization.zip}
          </p>
        {/if}
        {#if $organization.email}<p class="text-gray-600">
            {$organization.email}
          </p>{/if}
        {#if $organization.phone}<p class="text-gray-600">
            {$organization.phone}
          </p>{/if}
      </div>
      <div
        class="hidden print:inline sm:inline text-xl uppercase font-bold text-gray-800"
      >
        Invoice
      </div>
    </div>

    <div class="mb-12" />

    <div class="grid grid-cols-12 lg:gap-4">
      <div class="col-span-12 sm:hidden text-xs print:hidden">
        <p class="font-bold flex">
          <span class="w-24">Invoice No:</span>
          <span class="ml-4 w-2/5 font-normal flex justify-end"
            >#INV{('0000' + $printInvoice.invoiceNumber).slice(-5)}</span
          >
        </p>
        <p class="font-bold flex">
          <span class="w-24">Invoice Date:</span>
          <span class="ml-4 w-2/5 font-normal flex justify-end"
            >{dayjs($printInvoice.createdAt.seconds * 1000).format(
              'MM/DD/YYYY'
            )}</span
          >
        </p>
        <p class="font-bold flex">
          <span class="w-24">Due Date:</span>
          <span class="ml-4 w-2/5 font-normal flex justify-end"
            >{dayjs($printInvoice.dueDate.seconds * 1000).format(
              'MM/DD/YYYY'
            )}</span
          >
        </p>
      </div>

      <div
        class="col-span-12 print:col-span-4 sm:col-span-4 mt-4 sm:mt-0 print::mt-0"
      >
        <span class="flex w-5/6 text-sm uppercase text-gray-700 border-b"
          >Bill To</span
        >

        <div class="text-xs">
          <p>{$printInvoice.customer.name}</p>
          <p>{$printInvoice.customer.email}</p>
          <p>{$printInvoice.customer.address}</p>
          <p>
            {$printInvoice.customer.city}
            {#if $printInvoice.customer.state}{$printInvoice.customer.state}, {$printInvoice
                .customer.zip}{/if}
          </p>
          <!-- <p>{$printInvoice.customer.phone}</p> -->
        </div>
      </div>

      <div
        class="col-span-12 print:col-span-4 sm:col-span-4 mt-4 sm:mt-0 print::mt-0"
      >
        <span class="flex w-5/6 text-sm uppercase text-gray-700 border-b"
          >Ship To</span
        >
        <div class="text-xs">
          <p>{$printInvoice.customer.name}</p>
          <p>{$printInvoice.customer.email}</p>
          <p>{$printInvoice.customer.address}</p>
          <p>
            {$printInvoice.customer.city}
            {#if $printInvoice.customer.state}{$printInvoice.customer.state}, {$printInvoice
                .customer.zip}{/if}
          </p>
          <!-- <p>{$printInvoice.customer.phone}</p> -->
        </div>
      </div>

      <div
        class="hidden print:inline sm:inline print:col-span-4 sm:col-span-4 text-xs mt-4 sm:mt-0 print:mt-0"
      >
        <p class="font-bold flex justify-end">
          <span class="w-24">Invoice No:</span>
          <span class="ml-4 w-2/5 font-normal flex justify-end"
            >#INV{('0000' + $printInvoice.invoiceNumber).slice(-5)}</span
          >
        </p>
        <p class="font-bold flex justify-end">
          <span class="w-24">Invoice Date:</span>
          <span class="ml-4 w-2/5 font-normal flex justify-end"
            >{dayjs($printInvoice.createdAt.seconds * 1000).format(
              'MM/DD/YYYY'
            )}</span
          >
        </p>
        <p class="font-bold flex justify-end">
          <span class="w-24">Due Date:</span>
          <span class="ml-4 w-2/5 font-normal flex justify-end"
            >{dayjs($printInvoice.dueDate.seconds * 1000).format(
              'MM/DD/YYYY'
            )}</span
          >
        </p>
      </div>
    </div>

    <!-- <div class="flex w-full">
      <div class="w-full">
        <span class="flex w-5/6 text-sm uppercase text-gray-700 border-b"
          >Bill To</span
        >

        <div class="text-xs">
          <p>{$printInvoice.customer.name}</p>
          <p>{$printInvoice.customer.email}</p>
          <p>{$printInvoice.customer.address}</p>
          <p>{$printInvoice.customer.phone}</p>
        </div>
      </div>
      <div class="w-full">
        <span class="flex w-5/6 text-sm uppercase text-gray-700 border-b"
          >Ship To</span
        >
        <div class="text-xs">
          <p>{$printInvoice.customer.name}</p>
          <p>{$printInvoice.customer.email}</p>
          <p>{$printInvoice.customer.address}</p>
          <p>{$printInvoice.customer.phone}</p>
        </div>
      </div>
      <div class="w-full text-xs">
        <p class="font-bold flex justify-end">
          Invoice No: <span class="ml-4 w-2/5 font-normal flex justify-end"
            >#INV{('0000' + $printInvoice.invoiceNumber).slice(-5)}</span
          >
        </p>
        <p class="font-bold flex justify-end">
          Invoice Date: <span class="ml-4 w-2/5 font-normal flex justify-end"
            >{dayjs($printInvoice.createdAt.seconds * 1000).format(
              'MM/DD/YYYY'
            )}</span
          >
        </p>
        <p class="font-bold flex justify-end">
          Due Date: <span class="ml-4 w-2/5 font-normal flex justify-end"
            >{dayjs($printInvoice.dueDate.seconds * 1000).format(
              'MM/DD/YYYY'
            )}</span
          >
        </p>
      </div>
    </div> -->

    <div class="mb-12" />

    <div class="overflow-x-auto">
      <div class="inline-block overflow-hidden sm:w-full print:w-full">
        <table
          class="rounded-t-lg m-5 w-5/6 sm:w-full print:w-full mx-auto text-gray-800 border-2 border-gray-300 text-xs"
        >
          <thead class="px-4">
            <tr
              class="text-left border-b-2 border-gray-300 font-bold uppercase"
            >
              {#each headers as header}
                <th scope="col" class="px-6 py-3 tracking-wider">
                  {header}
                </th>
              {/each}
              <th scope="col" class="px-6 py-3 tracking-wider flex justify-end">
                Total
              </th>
            </tr>
          </thead>
          <tbody class="">
            {#each $printInvoice.lineItems as item}
              <tr class="border-b border-gray-200">
                <td class="px-6 py-2 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="text-xs font-medium text-gray-900">
                      {item.description}
                    </div>
                  </div>
                </td>
                <td class="px-6 py-2 whitespace-nowrap">
                  <div class="flex items-center ">
                    <div class="text-xs font-medium text-gray-900">
                      {item.quantity}
                    </div>
                  </div>
                </td>
                <td class="px-6 py-2 whitespace-nowrap">
                  <div class="flex items-center ">
                    <div class="text-xs font-medium text-gray-900">
                      {item.unitPrice}
                    </div>
                  </div>
                </td>
                <td class="px-6 py-2 whitespace-nowrap">
                  <div class="flex items-center justify-end">
                    <div class="text-xs font-medium text-gray-900">
                      <span
                        >{(
                          Number(item.quantity) * Number(item.unitPrice)
                        ).toFixed(2)}</span
                      >
                    </div>
                  </div>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    </div>

    <div class="flex">
      <span
        class="hidden print:flex md:flex items-center justify-center print:w-1/2 w-1/3 text-xs"
        >Thank you for your business!</span
      >

      <div class="w-full md:w-2/3 flex justify-end text-xs">
        <ul class="">
          <li class="mt-1 uppercase flex justify-end items-center">
            <span class="w-20 flex justify-start">Subtotal</span>
            <span class="w-24 border-b pr-6 flex justify-end"
              >{subtotal.toFixed(2)}</span
            >
          </li>
          <li class="mt-1 uppercase flex justify-end items-center">
            <span class="w-20 flex justify-start">Total Tax</span>
            <span class="w-24 pr-6 flex justify-end">{totalTax.toFixed(2)}</span
            >
          </li>
          <li
            class="py-4 text-sm mt-2 flex justify-end items-center border-t-2 border-gray-800"
          >
            Balance Due <span class="ml-2 border-b border-gray-700 px-6"
              ><span class="mx-1">$</span>{totalAmount.toFixed(2)}</span
            >
          </li>
        </ul>
      </div>
    </div>

    {#if $printInvoice.terms}
      <div class="mt-4 text-sm w-full sm:w-1/2 lg:w-1/3">
        <ul>
          <li class="font-bold border-b">Terms & Instructions</li>
          <li class="text-xs text-gray-600">{$printInvoice.terms}</li>
        </ul>
      </div>
    {/if}
  </div>

  <div class="mt-8 pe-no-print flex justify-end">
    <button
      class="w-48 rounded uppercase px-8 py-4 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
      on:click={printStatement}
    >
      Print
    </button>
  </div>
{/if}

<style type="text/css">
  @media print {
    .pe-no-print {
      display: none !important;
    }
  }
</style>
