<script>
  import dayjs from 'dayjs';
  import { Shadow } from 'svelte-loading-spinners';
  import Pagination from '../components/table/Pagination.svelte';
  import DataTable from '../components/table/DataTable.svelte';
  import TableHeader from '../components/table/TableHeader.svelte';
  import { statements, selectedOrgId } from '../utils/store';
  import { redirect } from '@sveltech/routify';
  import DeleteModal from './modals/DeleteModal.svelte';
  import { getNotificationsContext } from 'svelte-notifications';
  import { db } from '../utils/services';
  import { createEventDispatcher } from 'svelte';
  import Heading from './Heading.svelte';
  const dispatch = createEventDispatcher();

  const { addNotification } = getNotificationsContext();

  let loading = false;
  let rowDelete = false;
  let currentEntry = {};

  let title = 'Statements';
  let pageValues = [10, 25, 50, 100];
  let headers = ['Type', 'Name', 'Start Date', 'End Date', 'Delete', 'Print'];
  let keys = ['typePretty', 'name', 'startDate', 'endDate', 'delete', 'print'];

  let filteredData = [];
  let filterValue = '';

  let inputElement;
  let pageIndex = 1;
  let firstEntry = 1;
  let lastEntry = 5;
  let numPages = 1;
  let total = 0;
  let numPerPage = pageValues[0];

  if (
    !$selectedOrgId ||
    $selectedOrgId == 'undefined' ||
    $selectedOrgId == 'null'
  ) {
    $redirect('/settings');
  }

  function filterClose() {
    filterValue = '';
    changePage(1);
  }

  function changePage(page) {
    let newFirst = 0;
    let newLast = 0;

    let val = filterValue.toLowerCase();

    filteredData = $statements
      .filter(
        (value) =>
          value.type.toLowerCase().includes(val) ||
          value.category.toLowerCase().includes(val) ||
          value.method.toLowerCase().includes(val) ||
          value.source.name.toLowerCase().includes(val) ||
          value.totalAmount.toString().toLowerCase().includes(val) ||
          dayjs(new Date(value.date.seconds * 1000))
            .format('MM/DD/YYYY')
            .toLowerCase()
            .includes(val)
      )
      .sort(function (a, b) {
        return b.date - a.date;
      });

    total = filteredData.length;
    numPages = Math.floor(total / numPerPage) + 1;

    // Check if page bounds go higher than number of pages
    if (page > numPages || page < 1) {
      return;
    }

    // Calculate new page numbers
    if (page === 1) {
      newFirst = 1;
      newLast = numPerPage;
    } else {
      newFirst = (page - 1) * numPerPage + 1;
      newLast = Number(newFirst) + Number(numPerPage) - 1;
    }

    // Check if new calculated total is over the total items
    if (newFirst <= total) {
      firstEntry = newFirst;
    }
    // Check if last page is over the total items
    if (newLast > total) {
      lastEntry = total;
    } else {
      lastEntry = newLast;
    }

    pageIndex = page;
    filteredData = filteredData.slice(firstEntry - 1, lastEntry);
  }

  function changeNumPerPage(num) {
    numPerPage = num;
    filterClose();
  }

  function catchPaginationEvent(event) {
    switch (event.type) {
      case 'page':
        changePage(event.value);
        break;
      case 'numPerPage':
        changeNumPerPage(event.value);
        break;
      default:
        console.log('Unsupported event type: ' + event.type);
    }
  }

  function catchHeaderEvent(event) {
    switch (event.type) {
      case 'filter':
        // event.value should be the search bar value
        filterValue = event.value;
        changePage(1);
        break;
      case 'close':
        filterClose();
        break;
      default:
        console.log('Unsupported event type: ' + event);
    }
  }

  function rowClick(event) {
    dispatch('notify', event.detail.id);
  }

  function deleteRow(event) {
    currentEntry = event.detail;
    rowDelete = true;
  }

  function deleteRowConfirmation(event) {
    db.collection('statements')
      .doc($selectedOrgId)
      .collection('statements')
      .doc(currentEntry.id)
      .delete()
      .then(() => {
        addNotification({
          text: 'Successfully deleted account!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        rowDelete = false;
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to delete account.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
    rowDelete = false;
  }

  $: if (!$statements) {
    loading = true;
  } else {
    loading = false;
    total = $statements.length;
    numPages = Math.floor(total / numPerPage) + 1;
    changePage(1);
  }
</script>

{#if rowDelete}
  <DeleteModal
    bind:show={rowDelete}
    bind:entry={currentEntry}
    on:notify={deleteRowConfirmation}
  />
{/if}

{#if !loading}
  <Heading heading="Reports" />
{/if}

{#if loading}
  <div class="w-full h-full flex justify-center items-center">
    <Shadow size="60" unit="px" duration="1s" color="#3b82f6" />
  </div>
{:else if !$statements}
  <div class="flex items-center justify-center">
    <div class="w-96 text-center grid text-sm text-gray-600">
      <span class="text-red-600 text-lg ">Error</span>
      <span
        >Failed to load statements. Please reach out to support@sociusco.com for
        help.</span
      >
    </div>
  </div>
{:else}
  <div class="flex flex-col mt-4">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden light:border-b border-gray-200 dark:border-b-0 rounded-lg"
        >
          <TableHeader
            addNew={false}
            {inputElement}
            {filterValue}
            on:notify={(ev) => catchHeaderEvent(ev.detail)}
          />

          <DataTable
            on:notify={rowClick}
            on:delete={deleteRow}
            on:print={rowClick}
            {filteredData}
            {headers}
            {keys}
          />

          <div class="bg-white">
            <Pagination
              {pageIndex}
              {numPages}
              {firstEntry}
              {lastEntry}
              {total}
              {numPerPage}
              {pageValues}
              on:change={(ev) => catchPaginationEvent(ev.detail)}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
