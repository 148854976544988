<script>
  import { routes } from '../../utils/store.ts';
  import LogoutModal from '../LogoutModal.svelte';
  import { fly } from 'svelte/transition';
  import { quartOut } from 'svelte/easing';
  import { darkMode } from '../../utils/store';
  import Hamburger from 'svelte-hamburgers';

  export let show = false;

  let logout = false;

  function appear(node, { duration }) {
    return {
      duration,
      css: (t) => {
        const eased = quartOut(t);
        return `transform: scale(${0.8 * 1.25 ** eased});
                  opacity: ${eased};`;
      },
    };
  }
</script>

<svelte:head>
  <!-- Import base css -->
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/svelte-hamburgers@3/dist/css/base.css"
  />

  <!-- Import spin css (spin is default type) -->
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/svelte-hamburgers@3/dist/css/types/spin.css"
  />
</svelte:head>

{#if logout}
  <LogoutModal bind:logout />
{/if}

<div class="w-screen inline md:hidden">
  <div
    class="px-4 py-4 bg-white-400 dark:bg-gray-800 shadow-lg flex justify-between items-center text-socius-secondary dark:text-white"
  >
    <!-- <span class="cursor-pointer noprint" on:click={() => (show = !show)}>
      <i class="fa fa-bars" aria-hidden="true" />
    </span> -->
    {#if $darkMode}
      <Hamburger
        bind:open={show}
        --color="#38d3ff"
        --active-color="#38d3ff"
        --padding="4"
      />
    {:else}
      <Hamburger
        bind:open={show}
        --color="#153f59"
        --active-color="#153f59"
        --padding="4"
      />
    {/if}

    <div>
      {#if $darkMode}
        <img
          src="BusinessBuddyLogoDarkMode.svg"
          class="inline h-8 mr-2 noprint"
          alt="Socius Logo"
        />
      {:else}
        <img
          src="BusinessBuddyLogoLightMode.svg"
          class="inline h-8 mr-2 noprint"
          alt="Socius Logo"
        />
      {/if}
      <!-- <span class="noprint">Business Buddy</span> -->
    </div>

    <span class="invisible noprint"><i class="fas fa-user-circle" /></span>
  </div>

  {#if show}
    <div class="flex w-screen bg-white absolute z-50">
      <div class="flex-grow items-center rounded shadow-lg">
        <ul class="flex flex-col list-none dark:bg-gray-800">
          {#each $routes as route, i}
            <li
              class="flex items-center"
              on:click={() => (show = false)}
              transition:fly={{ y: -15, delay: 50 * i }}
            >
              <a
                class=" text-gray-700 dark:text-gray-300 px-2 py-2 flex items-center text-xs uppercase font-bold"
                href={route.route}
              >
                <i
                  class="{route.icon} mx-1 text-socius-secondary w-6 flex items-center justify-center"
                />{route.heading}
              </a>
            </li>
          {/each}
          <li
            class="flex items-center"
            on:click={() => (show = false)}
            transition:fly={{ y: -15, delay: 50 * $routes.length }}
          >
            <a
              class=" text-gray-700 dark:text-gray-300 px-2 py-2 flex items-center text-xs uppercase font-bold"
              href="settings"
              ><i
                class="fa fa-cog mx-1 text-socius-secondary w-6 flex items-center justify-center"
              /> Settings
            </a>
          </li>
          <li
            class="flex items-center"
            on:click={() => (show = false)}
            transition:fly={{ y: -15, delay: 50 * ($routes.length + 1) }}
          >
            <span
              on:click={() => (logout = true)}
              class="cursor-pointer text-gray-700 dark:text-gray-300 px-2 py-2 flex items-center text-xs uppercase font-bold"
              href="logout"
              ><i
                class="fa fa-sign-out-alt mx-1 text-socius-secondary w-6 flex items-center justify-center"
              />Logout
            </span>
          </li>
        </ul>
      </div>
    </div>
  {/if}
</div>

<style type="text/css">
  @media print {
    .noprint {
      display: none;
    }
  }
</style>
