<script>
  import { invoices, selectedOrgId, printInvoice } from '../../utils/store';
  import dayjs from 'dayjs';
  import { Shadow } from 'svelte-loading-spinners';
  import Pagination from '../../components/table/Pagination.svelte';
  import DataTable from '../../components/table/DataTable.svelte';
  import InvoiceModal from '../../components/modals/InvoiceModal.svelte';
  import CustomerModal from '../../components/modals/CustomerModal.svelte';
  import AccountModal from '../../components/modals/AccountModal.svelte';
  import DeleteModal from '../../components/modals/DeleteModal.svelte';
  import TableHeader from '../../components/table/TableHeader.svelte';
  import { redirect } from '@sveltech/routify';
  import { db } from '../../utils/services';
  import { getNotificationsContext } from 'svelte-notifications';
  import Heading from '../../components/Heading.svelte';

  const { addNotification } = getNotificationsContext();

  let modal = false;
  let customerModal = false;
  let accountModal = false;
  let loading = false;
  let rowDelete = false;
  let currentEntry = {};

  let edit = false;
  let id = '';
  let paid = false;
  let customer = '';
  let description = '';
  let amountTotal = (0).toFixed(2);
  let selectedDate = new Date(); // date user chose, defaults to today
  let selectedCategory = { value: 'Sales', label: 'Sales' };
  let selectedMethod = { value: 'Cash', label: 'Cash' };
  let lineItems = [];
  let terms = '';
  let taxRate = 0;
  let bankAccount;
  let transactionId = '';
  let transactionNumber = '';

  let pageValues = [10, 25, 50, 100];
  let headers = [
    'ID',
    'Customer',
    'Description/PO#',
    'Amount ($)',
    'Paid',
    'Due Date',
    'Delete',
    'Print',
  ];
  let keys = [
    'invoiceNumber',
    'customer.name',
    'description',
    'totalAmount',
    'paid',
    'dueDate',
    'delete',
    'print',
  ];

  let filteredData = [];
  let filterValue = '';

  let inputElement;
  let pageIndex = 1;
  let firstEntry = 1;
  let lastEntry = 5;
  let numPages = 1;
  let total = 0;
  let numPerPage = pageValues[0];

  if (
    !$selectedOrgId ||
    $selectedOrgId == 'undefined' ||
    $selectedOrgId == 'null'
  ) {
    $redirect('/settings');
  }

  function filterClose() {
    filterValue = '';
    changePage(1);
  }

  function changePage(page) {
    let newFirst = 0;
    let newLast = 0;

    let val = filterValue.toLowerCase();

    filteredData = $invoices
      .filter(
        (value) =>
          value.customer.name.toLowerCase().includes(val) ||
          value.description.toLowerCase().includes(val) ||
          value.totalAmount.toLowerCase().includes(val) ||
          value.paid.toString().toLowerCase().includes(val) ||
          dayjs(new Date(value.dueDate.seconds * 1000))
            .format('MM/DD/YYYY')
            .toLowerCase()
            .includes(val)
      )
      .sort(function (a, b) {
        // return b.date - a.date;
        return a.invoiceNumber < b.invoiceNumber ? 1 : -1;
      });

    total = filteredData.length;
    numPages = Math.floor(total / numPerPage) + 1;

    // Check if page bounds go higher than number of pages
    if (page > numPages || page < 1) {
      return;
    }

    // Calculate new page numbers
    if (page === 1) {
      newFirst = 1;
      newLast = numPerPage;
    } else {
      newFirst = (page - 1) * numPerPage + 1;
      newLast = Number(newFirst) + Number(numPerPage) - 1;
    }

    // Check if new calculated total is over the total items
    if (newFirst <= total) {
      firstEntry = newFirst;
    }
    // Check if last page is over the total items
    if (newLast > total) {
      lastEntry = total;
    } else {
      lastEntry = newLast;
    }

    pageIndex = page;
    filteredData = filteredData.slice(firstEntry - 1, lastEntry);
  }

  function changeNumPerPage(num) {
    numPerPage = num;
    filterClose();
  }

  function catchPaginationEvent(event) {
    switch (event.type) {
      case 'page':
        changePage(event.value);
        break;
      case 'numPerPage':
        changeNumPerPage(event.value);
        break;
      default:
        console.log('Unsupported event type: ' + event.type);
    }
  }

  function catchHeaderEvent(event) {
    switch (event.type) {
      case 'filter':
        // event.value should be the search bar value
        filterValue = event.value;
        changePage(1);
        break;
      case 'close':
        filterClose();
        break;
      case 'modal':
        clearValues();
        modal = true;
        break;
      default:
        console.log('Unsupported event type: ' + event);
    }
  }

  function rowClick(event) {
    edit = true;
    id = event.detail.id;
    paid = event.detail.paid;
    customer = event.detail.customer;
    description = event.detail.description;
    amountTotal = event.detail.totalAmount;
    transactionId = event.detail.transactionId;
    transactionNumber = event.detail.transactionNumber;
    selectedDate = new Date(event.detail.dueDate.seconds * 1000);
    selectedCategory = {
      value: event.detail.category,
      label: event.detail.category,
    };
    selectedMethod = {
      value: event.detail.method,
      label: event.detail.method,
    };
    lineItems = event.detail.lineItems;
    terms = event.detail.terms;
    taxRate = event.detail.taxRate;
    bankAccount = event.detail.bankAccount;
    modal = true;
  }

  function clearValues() {
    edit = false;
    id = '';
    paid = false;
    customer = '';
    description = '';
    amountTotal = (0).toFixed(2);
    selectedDate = new Date(); // date user chose, defaults to today
    selectedCategory = { value: 'Sales', label: 'Sales' };
    selectedMethod = { value: 'Cash', label: 'Cash' };
    lineItems = [{ description: '', quantity: 0, unitPrice: 0.0 }];
  }

  function printRow(event) {
    // console.log(event.detail);
    $printInvoice = event.detail;
    $redirect('/invoices/print-preview');
  }

  function deleteRow(event) {
    currentEntry = event.detail;
    rowDelete = true;
  }

  function deleteRowConfirmation(event) {
    console.log(event);
    db.collection('invoices')
      .doc($selectedOrgId)
      .collection('invoices')
      .doc(currentEntry.id)
      .delete()
      .then(() => {
        addNotification({
          text: 'Successfully deleted invoice!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        rowDelete = false;
      })
      .catch((error) => {
        console.log(error);
        addNotification({
          text: 'Failed to delete invoice.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
    rowDelete = false;
  }

  $: if (!$invoices) {
    loading = true;
  } else {
    loading = false;
    total = $invoices.length;
    numPages = Math.floor(total / numPerPage) + 1;
    changePage(1);
  }
</script>

{#if modal}
  <InvoiceModal
    on:notify={() => {
      customerModal = true;
      modal = false;
    }}
    bind:show={modal}
    {edit}
    {id}
    {paid}
    {customer}
    {description}
    {amountTotal}
    {selectedDate}
    {bankAccount}
    {selectedCategory}
    {selectedMethod}
    {lineItems}
    {terms}
    {taxRate}
    {transactionId}
    {transactionNumber}
  />
{/if}
{#if customerModal}
  <CustomerModal
    on:notify={() => {
      accountModal = true;
      customerModal = false;
      modal = false;
    }}
    bind:show={customerModal}
  />
{/if}
{#if accountModal}
  <AccountModal bind:show={accountModal} />
{/if}
{#if rowDelete}
  <DeleteModal bind:show={rowDelete} on:notify={deleteRowConfirmation} />
{/if}

{#if !loading}
  <Heading heading="Invoices" />
{/if}

{#if loading}
  <div class="w-full h-full flex justify-center items-center">
    <Shadow size="60" unit="px" duration="1s" color="#3b82f6" />
  </div>
{:else if !$invoices}
  <div class="flex items-center justify-center">
    <div class="w-96 text-center grid text-sm text-gray-600">
      <span class="text-red-600 text-lg ">Error</span>
      <span
        >Failed to load invoices. Please reach out to support@sociusco.com for
        help.</span
      >
    </div>
  </div>
{:else}
  <div class="flex flex-col mt-4">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden light:border-b border-gray-200 dark:border-b-0 rounded-lg"
        >
          <TableHeader
            {inputElement}
            {filterValue}
            on:notify={(ev) => catchHeaderEvent(ev.detail)}
          />

          <DataTable
            on:notify={rowClick}
            on:delete={deleteRow}
            on:print={printRow}
            {filteredData}
            {headers}
            {keys}
          />

          <Pagination
            {pageIndex}
            {numPages}
            {firstEntry}
            {lastEntry}
            {total}
            {numPerPage}
            {pageValues}
            on:change={(ev) => catchPaginationEvent(ev.detail)}
          />
        </div>
      </div>
    </div>
  </div>
{/if}
