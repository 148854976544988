<script>
  import { user } from '../../utils/store';

  $: displayName = $user ? $user.displayName.replace(/\s+$/, '') : '';

  const currentDate = new Date();
  const month = currentDate.toLocaleString('default', { month: 'short' });
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const today = day + ' ' + month + '. ' + year;
</script>

<div class="text-xs md:text-sm w-full mb-6 flex justify-between">
  <div class="">
    <span class="text-socius-primary dark:text-socius-secondary">Dashboard</span
    >
    <span class="text-gray-400 px-3">|</span>
    <span class="dark:text-white"
      >Hello, {displayName}
      <!-- <span class="hidden md:inline">, Welcome Back</span> -->
    </span>
    <span class="text-gray-400 px-3">|</span>
    <span class="hidden md:inline dark:text-white text-socius-primary"
      >Welcome back!</span
    >
  </div>
  <div class="text-gray-400 hidden md:inline">
    {today}
  </div>
  <div class="text-gray-400 inline md:hidden">
    {month + ' ' + day}
  </div>
</div>
