<script>
  export let label = '';
  export let value = '';
  export let disabled = false;
  export let placeholder = '';
</script>

{#if label != ''}
  <label for="value" class="text-small text-gray-700 dark:text-gray-400">
    {label}
  </label>
{/if}
<div
  class="relative dark:bg-gray-800 border-transparent border-b border-gray-600 focus:border-b-2 focus:border-socius-secondary transition duration-300 ease-in-out"
>
  <div
    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none dark:text-gray-300 dark:bg-gray-800"
  >
    <span class="text-gray-500 sm:text-sm dark:bg-gray-800 dark:text-gray-300">
      $
    </span>
  </div>
  <input
    type="text"
    bind:value
    {disabled}
    class="py-2 px-4 block w-full pl-7 pr-0 sm:pr-12 sm:text-sm focus:outline-none dark:bg-gray-800 dark:text-gray-300"
    {placeholder}
  />
  <div class="absolute inset-y-0 right-0 hidden sm:flex items-center ">
    <span
      class="py-2 px-4 h-full py-0 pl-2 pr-7 bg-transparent text-gray-500 sm:text-sm dark:bg-gray-800 dark:text-gray-300"
    >
      USD
    </span>
  </div>
</div>

<style>
  .text-small {
    font-size: 0.65rem;
  }
</style>
