<script>
  import {
    selectedOrgId,
    organizations,
    organization,
  } from '../../utils/store';
  import Select from '../../components/Select.svelte';
  import { loadData } from '../../utils/loadData';
  import Heading from '../../components/Heading.svelte';
  import DarkModeToggle from '../../components/DarkModeToggle.svelte';

  $: selectedOrg = $organization ? $organization : '';
  const getOptionLabel = (option) => option.name;
  const getSelectionLabel = (option) => option.name;
  const optionIdentifier = 'id';

  function handleSelect(event) {
    // console.log('selected item', event.detail);
    selectedOrg = event.detail;
    $organization = event.detail;
    $selectedOrgId = selectedOrg.id; // so the selection will persist
    loadData();
  }
</script>

<Heading heading="Settings" />

{#if !$selectedOrgId || $selectedOrgId == 'undefined' || $selectedOrgId == 'null'}
  <div class="mb-4 md:w-1/3 p-2 bg-yellow-400 text-yellow-800 rounded">
    <i class="fas fa-exclamation-triangle mr-2" />
    Please select an org below.
  </div>
{/if}

<div class="mb-4">
  <div class="flex justify-between">
    <div class="">
      <span class="text-small md:text-xs text-gray-600 dark:text-gray-200"
        >Business</span
      >

      <div class="flex">
        <div class="cursor-pointer w-48 border-b border-gray-300">
          <Select
            items={$organizations}
            showIndicator={true}
            isClearable={false}
            {getOptionLabel}
            {getSelectionLabel}
            {optionIdentifier}
            on:notify={handleSelect}
            bind:selectedValue={$organization}
          />
        </div>
      </div>
    </div>

    <DarkModeToggle />
  </div>
</div>

<!-- <Setup /> -->
<style>
  .text-small {
    font-size: 0.65rem;
  }
</style>
