<script>
  import { onMount } from 'svelte';
  import { darkMode } from '../../utils/store';
  import { convertBalance } from '../../utils/utils';

  export let title = 'Title';
  export let labels = [];
  export let series = [];
  export let total = 0;

  $: total = convertBalance(total);

  let chart;
  let chartDiv;

  let darkThemeBackgroundColor = {
    false: '#fff',
    true: '#1F2937',
  };
  let darkmode = {
    false: 'light',
    true: 'dark',
  };

  $: options = {
    series: series,
    labels: labels,
    chart: {
      type: 'polarArea',
      background: darkThemeBackgroundColor[$darkMode],
    },
    fill: {
      opacity: 0.8,
    },
    stroke: {
      show: false,
    },
    theme: {
      mode: darkmode[$darkMode],
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 0,
        },
        spokes: {
          strokeWidth: 0,
        },
      },
    },
    yaxis: {
      show: false,
    },
    legend: {
      position: 'bottom',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };

  function updateOptions() {
    if (!chart) return;
    options = {
      series: series,
      labels: labels,
      chart: {
        background: darkThemeBackgroundColor[$darkMode],
      },
      theme: {
        mode: darkmode[$darkMode],
        palette: 'palette1',
      },
    };
    // console.log('Updating!');
    chart.updateOptions(options);
  }

  onMount(() => {
    // console.log('on mount');
    chart = new ApexCharts(chartDiv, options);
    chart.render();
  });
  $: options && updateOptions();
</script>

<div class="">
  <div class="flex justify-between mb-2">
    <span
      class="text-gray-700 dark:text-white font-bold sm:small-text lg:text-xs "
      >{title}
    </span>
  </div>

  <div
    class="bg-white dark:bg-gray-800 rounded-lg shadow py-2 px-4 mb-2 w-full"
  >
    <div class="block">
      {#if total}
        <div class="py-2 flex items-center justify-end">
          <div class="flex items-center justify-between">
            <span
              class="row-span-1 text-gray-300 font-bold small-text dark:text-gray-500"
              style="font-size: 0.65rem"
            >
              ${total}</span
            >
          </div>
        </div>
      {/if}

      <div bind:this={chartDiv} />
    </div>
  </div>
</div>

<style>
  .small-text {
    font-size: 0.65rem;
  }
</style>
