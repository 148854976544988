<script>
  import { user } from '../../utils/store';
  import { firebaseAuth } from '../../utils/auth';

  function signout() {
    firebaseAuth.signOut().then(
      function () {
        console.log('Signed Out');
        $user = undefined;
        authUI.reset();
      },
      function (error) {
        console.error('Sign Out Error', error);
      }
    );
  }
</script>

<div class="flex justify-center items-center h-screen w-screen p-4">
  <div class="grid grid-row-4 flex justify-center items-center">
    <div class="row-span-1 flex justify-center items-center">
      <i class="text-8xl text-red-500 fas fa-ban" />
    </div>
    <div class="row-span-1 flex justify-center items-center">
      <span class="text-2xl md:text-4xl font-bold mr-2">403</span>
      <span class="text-xl md:text-3xl ">Forbidden</span>
    </div>
    <div class="row-span-1 flex justify-center items-center text-center">
      <span class="text-lg md:text-xl text-red-500"
        >You are not authorized to use this application.</span
      >
    </div>
    <div class="row-span-1 flex justify-center items-center text-center">
      <span class="text-sm text-gray-800"
        >Please ask your administrator to add you to Business Buddy or create an
        Organization <a href="https://sociusco.com/portal">here</a>.</span
      >
    </div>
    <div class="mt-4 row-span-1 flex justify-center items-center text-center">
      <span
        on:click={signout}
        class="text-sm text-gray-800 py-2 px-4 cursor-pointer bg-gray-100 rounded shadow hover:shadow-xl"
        >Logout</span
      >
    </div>
  </div>
</div>
