<script>
  import { convertBalance } from '../../utils/utils';

  export let title = '';
  export let color = 'blue';
  export let amount = 0;
  export let icon = 'fas fa-university';
  export let ytd = false;

  $: moneyAmount = convertBalance(amount);
</script>

<div class="bg-white dark:bg-gray-800 rounded-lg shadow py-2 px-4 w-full">
  <div class="block">
    <div class="py-2 flex items-center justify-between">
      <div class="flex items-center justify-between">
        <div
          class:bg-blue-100={color == 'blue'}
          class:bg-green-100={color == 'green'}
          class:bg-red-100={color == 'red'}
          class="flex p-4 mr-4 lg:mr-4 h-8 lg:h-1 w-8 lg:w-1 rounded-full shadow-outline flex justify-center items-center"
        >
          <i
            class:text-blue-600={color == 'blue'}
            class:text-green-600={color == 'green'}
            class:text-red-600={color == 'red'}
            class="{icon} text-xs"
          />
        </div>
        <div class="grid grid-row-2">
          <span
            class="row-span-1 text-gray-700 dark:text-white font-bold text-xs"
          >
            {title}</span
          >
          <span
            class:text-socius-secondary={color == 'blue'}
            class:text-green-600={color == 'green'}
            class:text-red-600={color == 'red'}
            class="row-span-1 text-xs lg:text-sm font-bold"
            >$ {moneyAmount}</span
          >
        </div>
      </div>

      {#if ytd}
        <span class="uppercase text-gray-400 small-text hidden md:inline"
          >YTD</span
        >
      {/if}
    </div>
  </div>
</div>

<style>
  .small-text {
    font-size: 0.65rem;
  }
</style>
