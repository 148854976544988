<script>
  import { redirect } from '@sveltech/routify';
  export let icon = '';
  export let title = '';
  export let route = '';

  function navigate() {
    $redirect(route);
  }
</script>

<span
  on:click={navigate(route)}
  class="cursor-pointer rounded-lg bg-white dark:bg-gray-800 p-2 text-xs text-gray-700 dark:text-white shadow w-full block h-full flex items-center justify-center text-black hover:bg-socius-secondary hover:text-white hover:font-bold "
>
  <i class="fas {icon} pr-2 text-socius-secondary" />{title}</span
>
