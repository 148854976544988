<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let checked = true;
  export let label = '';

  const handleChange = () => {
    dispatch('notify', { checked: checked, label: label });
  };
</script>

<div class="p-2">
  <label
    class="select-none custom relative cursor-pointer pl-8 text-xs flex items-center h-4 dark:text-gray-300"
    >{label}
    <input
      class="absolute opacity-0 left-0 top-0 cursor-pointer"
      type="checkbox"
      bind:checked
      on:change={handleChange}
    />
    <span class="h-4 w-4 checkmark absolute top-0 left-0 bg-gray-400" />
  </label>
</div>

<style>
  .custom:hover input ~ .checkmark {
    background-color: #a0aec0;
  }

  .custom input:checked ~ .checkmark {
    background-color: #38d3ff;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .custom input:checked ~ .checkmark:after {
    display: block;
  }

  .custom .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
</style>
