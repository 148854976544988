<script>
  import NavLink from './NavLink.svelte';
  import { routes, organization } from '../../utils/store.ts';
  import LogoutModal from '../LogoutModal.svelte';
  import { darkMode } from '../../utils/store';

  let logout = false;
</script>

{#if logout}
  <LogoutModal bind:logout />
{/if}

<div
  class="pe-no-print print:display-none h-screen bg-gray-100 md:bg-white md:dark:bg-gray-800 w-full flex flex-col"
>
  <!-- Header -->
  <div class="flex items-center justify-start md:justify-center p-4 md:h-24">
    {#if $darkMode}
      <img
        src="BusinessBuddyLogoDarkMode.svg"
        class="inline h-14"
        alt="Socius Logo"
      />
    {:else}
      <img
        src="BusinessBuddyLogoLightMode.svg"
        class="inline h-14"
        alt="Socius Logo"
      />
    {/if}
    <!-- <span class="hidden lg:inline dark:text-white"> Business Buddy </span> -->
  </div>

  <!-- Links -->
  {#each $routes as route}
    <NavLink heading={route.heading} icon={route.icon} route={route.route} />
  {/each}

  <div class="flex-grow" />

  <!-- Bottom -->
  <div class="mb-6">
    {#if $organization}
      <span
        class="w-full pl-4 pr-4 lg:pr-6 py-2 hidden md:flex text-xs text-gray-500 flex items-center justify-center"
        >{$organization.name}</span
      >
    {/if}
    <!-- <hr class="my-2 dark:bg-gray-300" /> -->
    <NavLink heading="Settings" icon="fa fa-cog" route="/settings" />
    <div
      on:click={() => (logout = true)}
      class="cursor-pointer hidden md:block md:pl-4 md:pr-6 py-2 text-sm text-gray-700 dark:text-gray-300 flex items-center justify-center md:justify-start hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-socius-secondary"
    >
      <i class="fa fa-sign-out-alt md:pl-2 md:pr-4" aria-hidden="true" />

      <!-- <img
        class="h-6 w-6 rounded-full"
        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        alt="" /> -->

      <span> Logout </span>
    </div>
  </div>
</div>

<style type="text/css">
  @media print {
    .pe-no-print {
      display: none !important;
    }
  }
</style>
