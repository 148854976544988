<script>
  import { params } from '@sveltech/routify';
  import { onMount } from 'svelte';
  import { db } from '../../utils/services';
  import { selectedOrgId } from '../../utils/store';

  import ProfitAndLoss from '../../components/statements/ProfitAndLoss.svelte';
  import TransactionLedger from '../../components/statements/TransactionLedger.svelte';
  import TotalOperatingExpense from '../../components/statements/TotalOperatingExpense.svelte';
  import AllStatements from '../../components/AllStatements.svelte';

  import Bowser from 'bowser';
  import { PrintElements } from '../../utils/print_elements';
  import { getNotificationsContext } from 'svelte-notifications';
  const { addNotification } = getNotificationsContext();

  const view = true;
  let name = '';
  let type = '';
  let formattedStartDate = '';
  let formattedEndDate = '';

  let el;
  let innerWidth = 0;
  const browser = Bowser.parse(window.navigator.userAgent);

  function loadStatement(id) {
    console.log('notified');
    // console.log('ID: ', id);
    db.collection('statements')
      .doc($selectedOrgId)
      .collection('statements')
      .doc(id)
      .get()
      .then((doc) => {
        console.log(doc);
        if (doc.exists) {
          const docData = doc.data();
          name = docData.name;
          type = docData.type;
          formattedStartDate = docData.startDate;
          formattedEndDate = docData.endDate;
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!');
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  }

  function printStatement() {
    // console.log('print!');
    // console.log('Browser: ', browser);
    if (
      browser.browser.name.includes('Safari') &&
      browser.platform.type === 'desktop'
    ) {
      document.execCommand('print');
    } else if (
      browser.browser.name.includes('Firefox') &&
      browser.platform.type !== 'desktop'
    ) {
      // not supported
      addNotification({
        text: "Firefox mobile isn't supported.",
        position: 'bottom-center',
        type: 'error',
        removeAfter: 2000,
      });
    } else {
      PrintElements.print(document.getElementById('TARGET'));
    }
  }

  onMount(() => {
    if ($params.id) {
      loadStatement($params.id);
    }
  });
</script>

<svelte:window bind:innerWidth />

<!-- <p class="h-4" on:click={print}>Print</p> -->

{#if type !== 'pnl' && type !== 'transactionLedger' && type !== 'totalOperatingExpense'}
  <AllStatements on:notify={(ev) => loadStatement(ev.detail)} />
{:else}
  <div class="bg-white dark:bg-gray-700">
    {#if type === 'pnl'}
      <div id="TARGET" class="p-4 print:p-0 rounded pagebreak">
        <ProfitAndLoss {view} {name} {formattedStartDate} {formattedEndDate} />
      </div>
    {:else if type === 'transactionLedger'}
      <div id="TARGET" class="p-4 print:p-0 rounded pagebreak">
        <TransactionLedger
          {view}
          {name}
          {formattedStartDate}
          {formattedEndDate}
        />
      </div>
    {:else if type === 'totalOperatingExpense'}
      <div id="TARGET" class="p-4 print:p-0 rounded pagebreak">
        <TotalOperatingExpense
          {view}
          {name}
          {formattedStartDate}
          {formattedEndDate}
        />
      </div>
    {/if}
    <div class="mt-8 pe-no-print flex justify-end">
      <button
        class="w-48 rounded uppercase px-8 py-4 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
        on:click={printStatement}
      >
        Print
      </button>
    </div>
  </div>
{/if}

<style type="text/css">
  @media print {
    .pe-no-print {
      display: none !important;
    }
  }
</style>
