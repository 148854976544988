<script>
  import dayjs from 'dayjs';
  import BankAccountModal from '../modals/BankAccountModal.svelte';
  import { createEventDispatcher } from 'svelte';
  import Tooltip from '../Tooltip.svelte';
  const dispatch = createEventDispatcher();

  export let account = {};
  export let selected = false;
  export let reset = false;

  let open = false;
  let showTooltip = false;

  $: reset ? (selected = false) : '';

  const selectAcc = () => {
    if (open == false) {
      selected = !selected;
      dispatch('selected', { selected: selected, account: account });
    }
  };

  const details = () => {
    selected = false;
    open = !open;
  };

  $: balance = parseFloat(account.balance.replace(',', ''))
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  let date = new Date(0);
  if (
    account != null &&
    account != undefined &&
    account.lastUpdatedAt != null &&
    account.lastUpdatedAt != undefined
  ) {
    date.setUTCSeconds(account.lastUpdatedAt.seconds);
  }

  $: formattedDate = dayjs(date).format('MM/DD/YYYY'); // pretty date that user chose
</script>

{#if open}
  <BankAccountModal
    bind:show={open}
    edit={true}
    id={account.id}
    name={account.name}
    accountType={account.accountType}
    accountNumber={account.accountNumber}
    balance={account.balance}
  />
{/if}

<Tooltip text="Click to select for deletion" {showTooltip}>
  <div
    class="rounded mb-1 px-4 py-4 dark:text-gray-300 bg-white dark:bg-gray-800 cursor-pointer md:cursor-default shadow-sm"
    class:border-2={selected}
    class:border-red-600={selected}
    class:bg-red-100={selected}
    class:shadow-lg={selected}
    class:dark:text-gray-300={!selected}
    on:click={selectAcc}
    on:mouseenter={() => (showTooltip = true)}
    on:mouseleave={() => (showTooltip = false)}
  >
    <div class="flex justify-between cursor-pointer">
      <div class="grid grid-row-3 md:text-xs">
        <div
          class:text-black={!selected}
          class:dark:text-gray-300={!selected}
          class:text-red-600={selected}
        >
          <span class="font-bold">{account.name}</span>
          <span class="text-xs">...{account.accountNumberLast4}</span>
        </div>
        <span
          class="text-xs md:small-text"
          class:text-black={!selected}
          class:dark:text-gray-300={!selected}
          class:text-red-600={selected}>Last updated {formattedDate}</span
        >
        <span
          class="text-2xl md:text-lg font-bold mt-4"
          class:text-socius-secondary={!selected}
          class:text-red-600={selected}>${balance}</span
        >
        <span
          class="text-xs"
          class:text-black={!selected}
          class:dark:text-gray-300={!selected}
          class:text-red-600={selected}>Available Balance</span
        >
      </div>

      <div
        class="hidden md:flex justify-center items-center mt-4 md:mt-0 md:pr-4"
      >
        <span
          class="flex justify-center items-center h-8 w-14 text-xs md:text-sm rounded cursor-pointer "
          class:text-socius-secondary={!selected}
          class:hover:bg-socius-secondary={!selected}
          class:hover:text-white={!selected}
          class:border-2={!selected}
          class:border-socius-secondary={!selected}
          class:text-red-600={selected}
          on:click={details}>View</span
        >
      </div>
    </div>
  </div>
</Tooltip>
