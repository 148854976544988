<script>
  import { onMount } from 'svelte';
  import { darkMode } from '../../utils/store';

  export let title = 'Title';
  export let series = [];
  export let colors = ['#00e396', '#e2004b', '#008ffb'];

  let chart;
  let chartDiv;

  let darkThemeBackgroundColor = {
    false: '#fff',
    true: '#1F2937',
  };
  let darkmode = {
    false: 'light',
    true: 'dark',
  };

  $: options = {
    series: series,
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
      background: darkThemeBackgroundColor[$darkMode],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
    },
    zoom: {
      enabled: true,
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      tickPlacement: 'on',
    },
    yaxis: {
      title: {
        text: '$ Amount',
      },
    },
    theme: {
      mode: darkmode[$darkMode],
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: darkmode[$darkMode],
      y: {
        formatter: function (val) {
          return '$ ' + val;
        },
      },
    },
    colors: colors,
  };

  function updateOptions() {
    if (!chart) return;
    options = {
      series: series,
      chart: {
        background: darkThemeBackgroundColor[$darkMode],
      },
      theme: {
        mode: darkmode[$darkMode],
      },
      tooltip: {
        theme: darkmode[$darkMode],
      },
    };
    // console.log('Updating!');
    chart.updateOptions(options);
  }

  onMount(() => {
    // console.log('on mount');
    chart = new ApexCharts(chartDiv, options);
    chart.render();
  });
  $: options && updateOptions();
</script>

<div class="flex justify-between mb-2">
  <span
    class="text-gray-700 dark:text-white font-bold sm:small-text lg:text-xs "
    >{title}</span
  >
</div>

<div class="bg-white dark:bg-gray-800 rounded-lg shadow py-2 px-4 mb-2 w-full">
  <div class="block p-2">
    <!-- <div class="py-2 flex items-center justify-between">
      <div class="flex items-center justify-between">
        <span
          class="row-span-1 text-gray-700 font-bold small-text dark:text-gray-300"
          style="font-size: 0.65rem"
        >
          Cash coming in and out of your business</span
        >
      </div>
    </div> -->

    <div bind:this={chartDiv} />
  </div>
</div>
