<script>
  import Dropzone from 'svelte-file-dropzone';
  import { saveAs } from 'file-saver';

  export let files = {
    accepted: [],
    rejected: [],
  };
  function handleFilesSelect(e) {
    const { acceptedFiles, fileRejections } = e.detail;
    files.accepted = [...files.accepted, ...acceptedFiles];
    files.rejected = [...files.rejected, ...fileRejections];
  }
  function handleRemoveFile(e, index) {
    files.accepted.splice(index, 1);
    files.accepted = [...files.accepted];
  }
  function handleRemoveAll() {
    files.accepted = [];
  }

  function download(e, index) {
    const file = files.accepted[index];
    saveAs(file);
    // console.log(file);

    // const url = URL.createObjectURL(file);
    // console.log('url: ', url);

    // // Create a new link
    // const anchor = document.createElement('a');
    // anchor.href = url;
    // anchor.download = file.name;

    // document.body.appendChild(anchor); // Append to the DOM
    // anchor.click(); // Trigger `click` event
    // document.body.removeChild(anchor); // Remove element from DOM

    // URL.revokeObjectURL(url);
  }
</script>

<div class="cursor-pointer dark:bg-gray-800">
  <Dropzone
    on:drop={handleFilesSelect}
    accept={['image/*', 'application/pdf']}
    containerClasses="dark:bg-gray-800"
  >
    <div class="text-xs dark:text-gray-300">
      <p>Drag 'n' drop some files here, or click to select files</p>
    </div>
  </Dropzone>
</div>
<div class="text-xs mt-4 dark:text-gray-300">
  {#if files.accepted.length > 0}
    <div class="flex justify-between">
      <span class="dark:text-gray-400">Files:</span>
      <button on:click={handleRemoveAll} class="p-1 rounded-sm text-red-500"
        >Remove All</button
      >
    </div>
  {/if}
  <ul>
    {#each files.accepted as item, index}
      <li>
        <i
          on:click={(e) => handleRemoveFile(e, index)}
          class="text-red-500 fas fa-trash cursor-pointer"
        />
        <i
          on:click={(e) => download(e, index)}
          class="ml-4 text-socius-alt fas fa-save cursor-pointer"
        />
        <span class="ml-4">{item.name}</span>
      </li>
    {/each}
  </ul>
</div>
