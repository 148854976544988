<script>
  import { db } from '../../utils/services';
  import { clickOutside } from '../../utils/clickOutside';
  import { selectedOrgId, user, chartOfAccounts } from '../../utils/store';
  import { getNotificationsContext } from 'svelte-notifications';
  import Input from '../Input.svelte';

  const { addNotification } = getNotificationsContext();

  export let show = false;

  export let edit = false;
  export let id = '';
  export let accountNum = $chartOfAccounts[0].accountNum + 1000;
  export let oldAccountNum = 0;
  export let name = '';
  export let description = '';

  let disabled;
  let error = '';
  $: if (accountNum == 1000 || accountNum == 2000 || accountNum == 3000)
    disabled = true;

  $: if (accountNum) error = '';

  function add() {
    let valid = true;

    // Find if the array contains an object by comparing the property value
    if ($chartOfAccounts.some((acc) => acc.accountNum === accountNum)) {
      valid = false;
      error = 'Account number already exists.';
    }

    if (accountNum % 1000 != 0) {
      valid = false;
      error = 'Account number must be in multiples of 1000.';
    }

    if (valid && !disabled) {
      error = '';
      db.collection('accounts')
        .doc($selectedOrgId)
        .collection('accounts')
        .add({
          accountNum: accountNum,
          organizationId: $selectedOrgId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: $user.uid,
          lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdatedBy: $user.uid,
          description: description,
          name: name,
        })
        .then(() => {
          addNotification({
            text: 'Successfully added account!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
          });
          show = false;
        })
        .catch((error) => {
          console.log(error);
          addNotification({
            text: 'Failed to add account.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
          });
        });
    }
  }

  function update() {
    let valid = true;

    // Find if the array contains an object by comparing the property value
    if ($chartOfAccounts.some((acc) => acc.accountNum === accountNum)) {
      if (oldAccountNum !== accountNum) {
        valid = false;
        error = 'Account number already exists.';
      }
    }

    if (accountNum % 1000 != 0) {
      valid = false;
      error = 'Account number must be in multiples of 1000.';
    }

    if (valid && !disabled) {
      db.collection('accounts')
        .doc($selectedOrgId)
        .collection('accounts')
        .doc(id)
        .update({
          accountNum: accountNum,
          lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastUpdatedBy: $user.uid,
          description: description,
          name: name,
        })
        .then(() => {
          addNotification({
            text: 'Successfully updated account!',
            position: 'bottom-center',
            type: 'success',
            removeAfter: 2000,
          });
          show = false;
        })
        .catch((error) => {
          console.log(error);
          addNotification({
            text: 'Failed to update account.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
          });
        });
    }
  }
</script>

<div
  class="fixed z-50 inset-0 overflow-y-auto"
  on:click_outside={() => (show = false)}
>
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >
    <div
      class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full md:max-w-xl lg:max-w-2xl xl:max-w-4xl"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <!-- Heading -->
            <div class="w-full flex justify-between">
              <h3
                class="text-lg leading-6 font-medium text-gray-900 dark:text-white"
              >
                {#if !edit}
                  New Account
                {:else}
                  Edit Account
                {/if}
              </h3>
              <span
                on:click={() => (show = false)}
                class="hidden md:flex text-cfa-secondary-gray hover:bg-socius-primary hover:text-white rounded-full w-8 h-8 flex justify-center items-center"
              >
                ×
              </span>
            </div>

            <!-- Body -->
            <div class="mt-2">
              <div class="grid grid-col-12 grid-row-5 mt-4">
                <!-- Row 1 -->
                <div class="row-span-1 flex justify-between w-full">
                  <div class="w-1/2 mr-1 md:mr-4">
                    <Input
                      label="Name"
                      bind:value={name}
                      placeholder="Name of account"
                      bind:disabled
                    />
                  </div>
                  <div class="w-1/2 ml-1 md:ml-4">
                    <Input
                      label="Description"
                      bind:value={description}
                      placeholder="Short description"
                      bind:disabled
                    />
                  </div>
                </div>

                <!-- Row 2 -->
                <div class="row-span-1 flex justify-between w-full mt-2">
                  <div class="w-1/2 pr-4">
                    <label
                      for="accountNumber"
                      class="text-small text-gray-700 dark:text-gray-400"
                    >
                      Account Number
                    </label>
                    <input
                      {disabled}
                      bind:value={accountNum}
                      type="number"
                      name="accountNumber"
                      step="1000"
                      max={$chartOfAccounts[0].accountNum + 1000}
                      min="4000"
                      class:text-gray-400={disabled}
                      class:text-gray-700={!disabled}
                      class="mb-2 outline-none dark:text-gray-300 border-transparent border-b border-gray-600 w-full py-2 pr-4 placeholder-gray-300 text-xs bg-transparent"
                      placeholder="Account number"
                    />
                    <p
                      class="text-xs p-2 text-red-500"
                      class:hidden={error === ''}
                    >
                      {error}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Footer -->
            <div
              class="mt-6 w-full flex items-center justify-center cursor-pointer"
            >
              <div class="w-1/2 flex justify-end mr-4">
                {#if !edit}
                  <span
                    on:click={add}
                    class="flex items-center justify-center text-xs text-socius-secondary h-8 w-28 rounded-full hover:bg-socius-secondary hover:text-white"
                  >
                    Add Account
                  </span>
                {:else}
                  <span
                    on:click={update}
                    class:cursor-not-allowed={disabled}
                    class:hover:bg-socius-primary={!disabled}
                    class:hover:text-white={!disabled}
                    class="flex items-center justify-center text-xs text-socius-alt h-8 w-28 rounded-full"
                  >
                    Update Account
                  </span>
                {/if}
              </div>
              <div class="w-1/2 flex justify-start ml-4">
                <span
                  on:click={() => (show = false)}
                  class="flex items-center justify-center text-xs text-red-600 hover:text-gray-900 h-8 w-16 rounded-full hover:bg-red-100"
                >
                  Close
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .text-small {
    font-size: 0.65rem;
  }
</style>
