<script>
  export let pageIndex;
  export let numPages;
  export let firstEntry;
  export let lastEntry;
  export let total;
  export let numPerPage;
  export let pageValues;

  import { createEventDispatcher } from 'svelte';
  import { fade } from 'svelte/transition';
  import { quartOut } from 'svelte/easing';
  const dispatch = createEventDispatcher();

  function changePage(page) {
    if (page !== pageIndex) {
      if (page >= 1) {
        dispatch('change', { type: 'page', value: page });
      } else {
        dispatch('change', { type: 'page', value: 1 });
      }
    }
  }

  function changeNumPerPage(num) {
    toggleSelect();
    dispatch('change', { type: 'numPerPage', value: num });
  }

  let showSelect = false;
  let toggleSelectDebounce;
  function toggleSelect() {
    clearTimeout(toggleSelectDebounce);
    toggleSelectDebounce = setTimeout(() => {
      showSelect = !showSelect;
    }, 10);
  }

  function appear(node, { duration }) {
    return {
      duration,
      css: (t) => {
        const eased = quartOut(t);
        return `transform: scale(${0.8 * 1.25 ** eased});
                  opacity: ${eased};`;
      },
    };
  }
</script>

<div class="pagination text-xs dark:text-gray-300 bg-white dark:bg-gray-800">
  <div class="page-size">
    <div class="page-size-label">Items per page:</div>
    <div class="itemToggle" on:click={toggleSelect}>
      {numPerPage}
      <span class="triangle" class:open={showSelect} />
    </div>
    {#if showSelect}
      <div
        class="pane"
        in:appear={{ duration: 150 }}
        out:fade={{ duration: 150 }}
      >
        <div class="pane-list">
          {#each pageValues as op}
            <div
              class:selected={numPerPage == op}
              on:click={changeNumPerPage(op)}
            >
              <span>{op}</span>
            </div>
          {/each}
        </div>
      </div>
    {/if}
  </div>

  <div class="entries text-xs">{firstEntry} - {lastEntry} of {total}</div>

  <nav aria-label="Page navigation">
    <ul class="inline-flex space-x-2 flex items-center justify-center">
      <li>
        <button
          on:click={() => changePage(pageIndex - 1)}
          class:text-gray-200={pageIndex <= 1}
          class="text-xs flex items-center justify-center w-8 h-8 text-socius-secondary transition-colors duration-150 rounded-full focus:shadow-outline hover:bg-socius-primary hover:text-white"
        >
          <svg class="w-5 h-10 fill-current" viewBox="0 0 20 20"
            ><path
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            /></svg
          ></button
        >
      </li>
      <li>
        <button
          on:click={() => changePage(pageIndex + 1)}
          class:text-gray-200={pageIndex >= numPages}
          class="text-xs flex items-center justify-center w-8 h-8 text-socius-secondary transition-colors duration-150 rounded-full focus:shadow-outline hover:bg-socius-primary hover:text-white"
        >
          <svg class="w-5 h-10 fill-current" viewBox="0 0 20 20"
            ><path
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            /></svg
          ></button
        >
      </li>
    </ul>
  </nav>
</div>

<style>
  /* Select options */
  .itemToggle {
    width: 55px;
    text-align: center;
  }
  .triangle {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 3px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid#e5e7eb;
    transition: all 150ms ease-out;
  }
  .triangle.open {
    border-top: 5px solid #38d3ff;
    transform: rotate(-180deg);
  }

  .pane {
    color: #e5e7eb;
    position: absolute;
    padding: 1em 2.5em 1em 1em;
    width: 10px;
    background-color: #fff;
    border-radius: 7px;
    margin-top: 2.5em;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    transform-origin: 50% 0;
    right: 15em;
  }

  .pane-list {
    font-size: 16px;
    display: grid;
    grid-template-rows: auto;
    row-gap: 10px;
  }
  .pane-list > div {
    cursor: pointer;
    display: grid;
    grid-template-columns: 5fr 12fr;
  }
  .pane-list > div.selected {
    color: #38d3ff;
  }
  /* End select options */

  .entries {
    width: 125px;
    text-align: center;
  }

  /* General stuff */
  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 56px;
    width: 100%;
    padding: 0 8px;
    flex-wrap: wrap-reverse;
  }

  .page-size {
    display: flex;
    align-items: baseline;
    margin-right: 8px;
  }
  .page-size-label {
    margin: 0 4px;
  }
</style>
