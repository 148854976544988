import "firebase/auth";
// import { sociusAppConfig } from './secret'

var authUIConfig = {
    // signInSuccessUrl: 'http://localhost:5005',
    signInSuccessUrl: `${process.env.SIGNINSUCCESSURL}`,
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    ],
    signInFlow: 'popup',
    // Terms of service url.
    tosUrl: 'https://sociusco.com/tos',
    // Privacy policy url.
    privacyPolicyUrl: 'https://sociusco.com/privacy-policy'

};

// Initialize the FirebaseUI Widget using Firebase.
// const sociusFirebase = firebase.initializeApp(sociusAppConfig, "socius");
let firebaseAuth = firebase.auth();
var sessionPersistence = firebase.auth.Auth.Persistence.SESSION;
var authUI = new firebaseui.auth.AuthUI(firebaseAuth);

const auth = {

    async login(email, password) {
        console.log(email);
        console.log(password);

        let usr;
        let err = '';
        await firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(function () {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                firebaseAuth
                    .signInWithEmailAndPassword(email, password)
                    .then((res) => {
                        console.log("res in auth.js");
                        console.log(res.user);
                        usr = res.user;
                    })
                    .catch((error) => {
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log('[' + errorCode + '] - ' + errorMessage);

                        console.log(error);
                        if (errorCode != undefined && errorCode.includes('user-not-found')) {
                            err = 'User does not exist. Please sign up below.';
                        } else {
                            err = 'Failed signing in. Please try again or contact support.';
                        }
                    });
            })
            .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                if (errorCode != undefined && errorCode.includes('user-not-found')) {
                    err = 'User does not exist. Please sign up below.';
                } else {
                    err = 'Failed signing in. Please try again or contact support.';
                }
            });

        console.log("about to call signin with email and pass")
        return { user: usr, error: err }
    },

    async signupUser(firstName, email, password, phoneNumber) {
        let err;
        let usr;
        let verified = false;

        await firebaseAuth
            .createUserWithEmailAndPassword(email, password)
            .then((res) => {
                usr = auth.currentUser;

                usr
                    .updateProfile({
                        displayName: firstName,
                        phoneNumber: phoneNumber,
                    })
                    .then(function () {
                        console.log('Everything looks great!');
                        console.log(usr);

                        usr.sendEmailVerification().then(function () {
                            console.log("Verification email successfully sent");
                        }).catch(function (error) {
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            console.log('[' + errorCode + '] - ' + errorMessage);

                            if (errorCode != undefined && errorCode.includes('email-already-in-use')) {
                                err = 'Email already in use. Please try another email';
                            } else {
                                err =
                                    'Failed signing up. Please try again or contact support.';
                            }
                        });


                    })
                    .catch(function (error) {
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log('[' + errorCode + '] - ' + errorMessage);

                        if (errorCode != undefined && errorCode.includes('email-already-in-use')) {
                            err = 'Email already in use. Please try another email';
                        } else {
                            err =
                                'Failed signing up. Please try again or contact support.';
                        }

                    });

            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log('[' + errorCode + '] - ' + errorMessage);


                if (errorCode != undefined && errorCode.includes('email-already-in-use')) {
                    err = 'Email already in use. Please try another email';
                } else {
                    err =
                        'Failed signing up. Please try again or contact support.';
                }

            });

        return { usr, verified, err };
    },

    async sendVerificationEmail() {
        let usr = firebaseAuth.currentUser;
        let success = false;

        await usr.sendEmailVerification().then(function () {
            console.log("Verification email successfully sent");
            success = true;
        }).catch(function (error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log('[' + errorCode + '] - ' + errorMessage);
            success = false;
        });

        return success;
    }
}
export { auth, authUI, authUIConfig, firebaseAuth, sessionPersistence };