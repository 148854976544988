<script lang="ts">
  import ProgressBar from '@okrad/svelte-progressbar';
  import { convertBalance } from '../../utils/utils';

  export let unpaidInvoices = 0;
  export let lateInvoices = 0;
  export let amtUnpaid = 0;
  export let amtLate = 0;

  $: moneyAmtUnpaid = convertBalance(amtUnpaid);
  $: moneyAmtLate = convertBalance(amtLate);
  $: openButNotLate = unpaidInvoices - lateInvoices;
  $: series = [
    {
      perc: (lateInvoices / unpaidInvoices) * 100,
      color: '#E2004B',
    },
    {
      perc: (openButNotLate / unpaidInvoices) * 100,
      color: '#9CA3AF',
    },
  ];
</script>

<div class="flex justify-between mb-2">
  <span
    class="text-gray-700 dark:text-white font-bold sm:small-text lg:text-xs "
    >Open and Unpaid Invoices</span
  >
</div>

<div class="bg-white dark:bg-gray-800 rounded-lg shadow py-2 px-4 mb-2 w-full">
  <div
    class="flex justify-between text-xs font-bold dark:text-gray-300 mb-2 mt-2"
  >
    <div class="">
      <span class="block text-red-600 text-base">$ {moneyAmtLate}</span>
      <span class=""
        >Overdue
        <span class="font-normal">({lateInvoices}) </span>
      </span>
    </div>
    <div class="">
      <span class="block text-right text-green-600 text-base"
        >$ {moneyAmtUnpaid}</span
      >
      <span class="flex justify-end"
        ><span class="font-normal mr-1">({openButNotLate}) </span>
        Open
      </span>
    </div>
  </div>

  <div class="block md:hidden lg:block mb-4">
    <ProgressBar
      bind:series
      thickness={7}
      cls="w-full"
      width={300}
      showProgressValue={false}
    />
  </div>
</div>
