<script lang="ts">
  import Navbar from '../components/nav/Navbar.svelte';
  import MobileSidenav from '../components/nav/MobileSidenav.svelte';
  import Auth from '../components/auth/Auth.svelte';
  import NotAuthorized from '../components/auth/NotAuthorized.svelte';
  import Notifications from 'svelte-notifications';
  import {
    user,
    claims,
    access,
    selectedOrgId,
    organization,
    idToken,
    darkMode,
    userSettings,
  } from '../utils/store';
  import { sessionPersistence, authUI, firebaseAuth } from '../utils/auth';
  import { loadData, loadOrgs } from '../utils/loadData';
  import { Shadow } from 'svelte-loading-spinners';
  import SessionTimerModal from '../components/modals/SessionTimerModal.svelte';
  import { calculateHeight } from '../utils/utils';
  import EmailVerification from '../components/auth/EmailVerification.svelte';

  let loading = true;

  let innerHeight;
  $: calculateHeight(innerHeight);

  let sessionTimeout: ReturnType<typeof setTimeout> = null;
  const sessionDuration = 1000 * 60 * 60; //* 24 * 30;
  let millisecondsUntilExpiration;
  let minutes;
  let seconds;
  let currentTimer;

  let show = false;
  $: if (minutes < 2 && seconds > 0 && $user) {
    show = true;
  }

  function refreshToken() {
    console.log('refreshing token');
    show = false;
    const now = new Date().getTime();
    createTimeout(now);
    startTimer(now);
  }

  function createTimeout(time) {
    // Create a session timeout which signs the user out.
    // Make sure all the times are in milliseconds!
    sessionTimeout && clearTimeout(sessionTimeout);
    sessionTimeout = null;
    // 1000ms in 1 sec, 60s in 1 min, 60min in 1 hour
    millisecondsUntilExpiration = sessionDuration - (Date.now() - time);
    // console.log('Setting timeout for: ', millisecondsUntilExpiration);
    sessionTimeout = setTimeout(
      () => firebaseAuth.signOut(),
      millisecondsUntilExpiration
    );
  }

  function startTimer(time) {
    let countdownTime = time + sessionDuration;
    clearInterval(currentTimer);
    // Update the count down every 1 second
    currentTimer = setInterval(function () {
      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countdownTime - now;

      // Time calculations for days, hours, minutes and seconds
      minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(currentTimer);
      }
    }, 1000);
  }

  $: if (
    $userSettings &&
    $userSettings.businessBuddy &&
    $userSettings.businessBuddy.darkMode != undefined
  ) {
    $darkMode = $userSettings.businessBuddy.darkMode;
  } else {
    $darkMode = !matchMedia('(prefers-color-scheme: light)').matches;
  }

  function handleSession(token) {
    $idToken = JSON.stringify(token.token);
    setSessionCookie();
    $claims = token.claims;

    // Make sure all the times are in milliseconds!
    const authTime = token.claims.auth_time * 1000;
    createTimeout(authTime);
    startTimer(authTime);
  }

  function checkSessionCookie() {
    console.log('checking cookie session');
    fetch(`/auth/status`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((result) => {
        result
          .json()
          .then((data) => {
            signInWithCustomToken(data['custom_token']);
          })
          .catch((err) => {
            console.log('error getting text', err);
          });

        if (result.ok != true) {
          console.log(`error with [${result.status}]: ${result.statusText}`);
        }
      })
      .catch((err) => {
        console.log('error checking cookie', err);
      });
  }

  async function signInWithCustomToken(token: string) {
    await firebaseAuth
      .setPersistence(sessionPersistence)
      .then(function () {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        firebaseAuth
          .signInWithCustomToken(token)
          .then((userCredential) => {
            $user = userCredential.user;
            signInAndLoadData();
          })
          .catch((error) => {
            console.log(`[${error.code}] ${error.message}`);
          });
      })
      .catch(function (error) {
        console.log(`[${error.code}] ${error.message}`);
      });
  }

  function signInAndLoadData() {
    $user
      .getIdTokenResult(true)
      .then((idTokenResult) => {
        handleSession(idTokenResult);
        if (
          $claims['business_buddy'] !== undefined &&
          $claims['business_buddy'] !== null &&
          $claims['business_buddy'].length > 0
        ) {
          $access = true;

          loadOrgs($claims['business_buddy']);
          loadData();
        }
        loading = false;
      })
      .catch((error) => {
        console.log('Failed to get claims: ', error);
        loading = false;
      });
  }

  function setSessionCookie() {
    let value_or_null = (document.cookie.match(
      /^(?:.*;)?\s*__session\s*=\s*([^;]+)(?:.*)?$/
    ) || [, null])[1];

    // only set cookie if it isn't already set
    if (value_or_null == null) {
      fetch(`/auth/session`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${$idToken.replaceAll(/"/g, '')}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          // console.log(res);
          if (res.ok == true) {
            console.log('successfully set cookie');
          } else {
            console.log(
              `error setting cookie. [${res.status}]: ${res.statusText}`
            );
          }
        })
        .catch((err) => {
          console.log('error getting billing portal url', err);
        });
    }
  }

  firebaseAuth.onAuthStateChanged((res) => {
    loading = true;
    // let sessionTimeout: ReturnType<typeof setTimeout> = null;
    if (res) {
      console.log('Auth state changed');
      $user = res;

      if (res != null) {
        signInAndLoadData();
      }
    } else {
      loading = false;
      authUI.reset();
      sessionTimeout && clearTimeout(sessionTimeout);
      sessionTimeout = null;
      show = false;
      $user = undefined;
      $selectedOrgId = null;
      console.log('No user signed in.');

      checkSessionCookie();
    }
  });
</script>

<svelte:window bind:innerHeight />

{#if show}
  <SessionTimerModal
    bind:show
    {minutes}
    {seconds}
    on:notify={() => {
      refreshToken();
    }}
  />
{/if}

<div class="dark:bg-gray-800" class:dark={$darkMode}>
  <Notifications>
    {#if loading}
      <div class="w-full h-full flex justify-center items-center">
        <Shadow size="60" unit="px" duration="1s" color="#3b82f6" />
      </div>
    {:else if $user && $user.emailVerified}
      {#if $access && !loading}
        <div class="grid grid-cols-12">
          <div class="hidden md:inline md:col-span-2 dark:bg-gray-800">
            <Navbar />
          </div>
          <!-- <div class="md:pt-20 w-full h-full max-w-screen-xl px-3 pt-16 pb-4 mx-auto text-center"></div> -->
          <MobileSidenav />
          <div
            class="col-span-12 md:col-span-10 w-full py-4 px-4 md:py-6 md:px-6 lg:py-8 lg:px-8 bg-gray-100 dark:bg-gray-700"
          >
            <slot />
          </div>
        </div>
      {:else}
        <NotAuthorized />
      {/if}
    {:else if $user && !$user.emailVerified}
      <EmailVerification />
    {:else}
      <Auth />
    {/if}
  </Notifications>
</div>
