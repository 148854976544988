import { db } from './services';
// import { sociusDb } from './services';
import { get } from 'svelte/store';
import {
    chartOfAccounts,
    invoices,
    customers,
    transactions,
    selectedOrgId,
    statements,
    settings,
    bankBalance,
    vendors,
    organization,
    organizations,
    user,
    userSettings,
    darkMode,
    bankAccounts,
} from './store'

export function loadOrgs(orgs) {
    const orgId = get(selectedOrgId);
    try {
        db.collection('organizations').where(firebase.firestore.FieldPath.documentId(), "in", orgs).onSnapshot((querySnapshot) => {
            let docs = [];
            querySnapshot.forEach((doc) => {
                if (doc != null && doc != undefined && doc.exists) {
                    docs.push({ ...doc.data(), id: doc.id });
                    if (doc.id == orgId) {
                        organization.set({ ...doc.data(), id: doc.id })
                    }
                }
            });
            organizations.set(docs);
        });
    } catch (error) {
        console.log("Error in loading organizations: ", error);
    }
}

export function loadData() {
    const orgId = get(selectedOrgId);
    const userId = get(user).uid;

    if (orgId == "") {
        return
    }

    // console.log("orgId: ", orgId);
    // console.log('User: ', get(user).getIdToken());
    // console.log('loading data...');

    try {
        db.collection('transactions').doc(orgId).collection('transactions').onSnapshot((querySnapshot) => {
            let docs = [];
            querySnapshot.forEach((doc) => {
                if (doc != null && doc != undefined && doc.exists) {
                    docs.push({ ...doc.data(), id: doc.id });
                }
            });
            docs.sort(function (a, b) {
                return b.date - a.date;
            });
            transactions.set([...docs]);
        }, error => {
            console.log("Error loading transactions. ", error)
        });
        db.collection('accounts').doc(orgId).collection('accounts').onSnapshot((querySnapshot) => {
            let docs = [{
                id: 1000,
                name: "Utilities",
                description: "Utilities",
                accountNum: 1000
            },
            {
                id: 2000,
                name: "Customers",
                description: "Customers",
                accountNum: 2000
            },
            {
                id: 3000,
                name: "Vendors",
                description: "Vendors",
                accountNum: 3000
            }];
            querySnapshot.forEach((doc) => {
                if (doc != null && doc != undefined && doc.exists) {
                    docs.push({ ...doc.data(), id: doc.id });
                }
            });
            docs.sort(function (a, b) {
                return b.accountNum - a.accountNum;
            });
            chartOfAccounts.set([...docs]);
        }, error => {
            console.log("Error loading accounts. ", error)
        });
        db.collection('customers').doc(orgId).collection('customers').onSnapshot((querySnapshot) => {
            let docs = [];
            querySnapshot.forEach((doc) => {
                if (doc != null && doc != undefined && doc.exists) {
                    docs.push({ ...doc.data(), id: doc.id });
                }
            });
            customers.set([...docs]);
        }, error => {
            console.log("Error loading customers. ", error)
        });
        db.collection('invoices').doc(orgId).collection('invoices').onSnapshot((querySnapshot) => {
            let docs = [];
            querySnapshot.forEach((doc) => {
                if (doc != null && doc != undefined && doc.exists) {
                    docs.push({ ...doc.data(), id: doc.id });
                }
            });
            invoices.set([...docs]);
        }, error => {
            console.log("Error loading invoices. ", error)
        });
        db.collection('vendors').doc(orgId).collection('vendors').onSnapshot((querySnapshot) => {
            let docs = [];
            querySnapshot.forEach((doc) => {

                if (doc != null && doc != undefined && doc.exists) {
                    docs.push({ ...doc.data(), id: doc.id });
                }
            });
            vendors.set([...docs]);
        }, error => {
            console.log("Error loading vendors. ", error)
        });
        db.collection('statements').doc(orgId).collection('statements').orderBy("createdAt", "desc").onSnapshot((querySnapshot) => {
            let docs = [];
            querySnapshot.forEach((doc) => {
                if (doc != null && doc != undefined && doc.exists) {
                    docs.push({ ...doc.data(), id: doc.id });
                }
            });
            statements.set([...docs]);
        }, error => {
            console.log("Error loading statements. ", error)
        });
        db.collection('settings').doc(orgId).onSnapshot((doc) => {
            if (doc != null && doc != undefined && doc.exists && Object.entries(doc.data()).length !== 0) {
                settings.set(doc.data())
            }
        }, error => {
            console.log("Error loading settings. ", error)
        });

        db.collection('bank_accounts').doc(orgId).collection('bank_accounts').onSnapshot((querySnapshot) => {
            let docs = []
            let bb = 0.0
            querySnapshot.forEach((doc) => {
                if (doc != null && doc != undefined && doc.exists) {
                    docs.push({ ...doc.data(), id: doc.id });
                    bb = bb + parseFloat(doc.data().balance);
                }
            });
            bankBalance.set(bb)
            bankAccounts.set([...docs]);
        }, error => {
            console.log("Error loading bank accounts. ", error)
        });

        db.collection('user_settings').doc(userId).onSnapshot((doc) => {
            if (doc != null && doc != undefined && doc.exists && Object.entries(doc.data()).length !== 0) {
                let settings = doc.data()
                userSettings.set(settings)
                try {
                    if (settings && settings.businessBuddy && settings.businessBuddy.darkMode != undefined) {
                        darkMode.set(settings.businessBuddy.darkMode)
                    } else {
                        darkMode.set(!matchMedia('(prefers-color-scheme: light)').matches)
                    }
                } catch (err) {
                    console.log('failed to set user settings', err);
                };
            }
        }, error => {
            console.log("Error loading user settings. ", error)
        });

        // db.collection('organizations').doc(orgId).onSnapshot((doc) => {
        //     if (doc != null && doc != undefined && doc.exists && Object.entries(doc.data()).length !== 0) {
        //         organization.set({ ...doc.data(), id: doc.id });
        //     } else {
        //         console.log("doc.data", doc.data())
        //         console.log('Organization doc doesnt exist')
        //     }
        // }, error => {
        //     console.log("Error loading organization. ", error)
        // });

        // db.collection('organizations').doc(orgId).onSnapshot((querySnapshot) => {
        //     let docs = [];
        //     querySnapshot.forEach((doc) => {
        //         if (doc != null && doc != undefined && doc.exists) {
        //             console.log("pushing org")
        //             docs.push({ ...doc.data(), id: doc.id });
        //         } else {
        //             console.log("doc is null or doesn't exist")
        //         }
        //     });
        //     console.log("Loaded org: ", docs[0])
        //     organization.set(docs[0]);
        // }, error => {
        //     console.log("Error loading organization. ", error)
        // });
    } catch (error) {
        console.log("Error in load data: ", error);
    }
}