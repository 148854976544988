<script>
  import { redirect } from '@sveltech/routify';

  import { firebaseAuth, authUI } from '../utils/auth';
  import { user } from '../utils/store';

  export let logout = false;

  function callAuthLogout() {
    console.log('calling auth function to logout');
    fetch(`/auth/logout`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((result) => {
        if (result.ok == true) {
          console.log('successfully logged out from auth function');
        } else {
          console.log(`error with [${result.status}]: ${result.statusText}`);
        }

        signout();
      })
      .catch((err) => {
        console.log('error logging out', err);
        signout();
      });
  }

  function signout() {
    firebaseAuth.signOut().then(
      function () {
        console.log('Signed Out');
        $user = undefined;
        authUI.reset();
        $redirect('/');
      },
      function (error) {
        console.error('Sign Out Error', error);
      }
    );
  }
</script>

<div class="fixed z-50 inset-0 overflow-y-auto">
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 dark:bg-gray-800 opacity-75" />
    </div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >
    <div
      class="inline-block align-bottom bg-white dark:bg-gray-700 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white dark:bg-gray-700 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <!-- Heroicon name: exclamation -->
            <svg
              class="h-6 w-6 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300"
              id="modal-headline"
            >
              Logout
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500 dark:text-gray-400">
                Are you sure you want to log out? Select "Yes" below if you are
                ready to end your current session.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-neutral-background dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
      >
        <button
          on:click={callAuthLogout}
          type="button"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Yes
        </button>
        <button
          on:click={() => (logout = false)}
          type="button"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-white text-base font-medium text-gray-800 hover:bg-gray-50 dark:hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<style>
</style>
