<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  export let text = 'Add New';
</script>

<button
  on:click={() => dispatch('notify', true)}
  class="fixed bottom-0 right-0 px-4 py-2 mb-12 mr-12 rounded-full bg-socius-secondary text-white text-xs hover:font-bold flex items-center justify-center"
  ><i class="fas fa-plus-circle mr-2" />{text}</button
>
